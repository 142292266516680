import React from "react";
import PropTypes from "prop-types";
import StoryCard from "../../atoms/story-card-dl-4-3-ml-5-4";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import BundleSmallStoryCard from "../../atoms/bundle-small-story-card";
import { DfpAd } from "../../atoms/dfp-ad/index";

const BundleSixStory6s = ({ collection, accentColor }) => {
  const { items } = collection;
  const stories =
    items
      .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
      .slice(0, 6)
      .map(({ story }) => (
        <BundleSmallStoryCard className={styles["story-card"]} key={story.id} story={story} subheadline={false} />
      )) || [];

  if (!stories.length) {
    return null;
  }

  return (
    <div className={`${styles["six-story-4s"]}`}>
      <div className={`container`}>
        <div className={styles["six-story-grid"]}>
          <div className={`${styles["story-container"]}`}>{stories}</div>
          <div>
            <DfpAd className="ad_300_250 hidden-mobile" adtype="Vikatan_mrec_mid_SP" />
          </div>
        </div>
      </div>
    </div>
  );
};

BundleSixStory6s.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    slug: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(StoryCard.propTypes))
  })
};
BundleSixStory6s.storyLimit = 4;

export default BundleSixStory6s;
