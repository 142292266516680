import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import get from "lodash/get";
import { fetchMatch } from "../../../api";
import MatchCard from "../../atoms/match-card";
import PlayerTableList from "../../atoms/player-table-list";

export class IplPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  componentDidMount() {
    const slug = get(this.props, ["data", "collectionSlug"], "");
    fetchMatch(slug).then(list => {
      this.setState({ list });
    });
  }

  render() {
    const { list } = this.state;
    const listCard = get(list, ["data", "items", "card"], []);
    const name = get(list, ["data", "items", "card", "short_name"], []);
    const longDescription = get(list, ["data", "items", "card", "long_description"], []);
    // const teamAplayers = get(list, ["data", "items", "card", "teams", "a", "match", "players"], []);
    // const teamBplayers = get(list, ["data", "items", "card", "teams", "b", "match", "players"], []);
    // const teamBlist = get(list, ["data", "items", "card", "teams", "b", "match", "playing_xi"], []);

    return (
      <div className={`main-page ${styles["default-section-page-container"]}`}>
        <div />
        <div className={styles["default-section-page"]}>
          <div className="container">
            <h1 className={styles["headline"]}>{name} MATCH RESULT FULL SCORECARD</h1>
          </div>
          <div className={styles["matchcard-bg"]}>
            <div className="container">
              <MatchCard item={listCard} className={styles.card} />
            </div>
          </div>
          <div className="container">
            <p className={styles["match-desc"]}>{longDescription}</p>
            <PlayerTableList item={listCard} teamGroup="a" />
            <PlayerTableList item={listCard} teamGroup="b" />
          </div>
        </div>
        <div />
      </div>
    );
  }
}

IplPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    section: PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.shape({
        color: PropTypes.string
      })
    }),
    parentSection: PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.shape({
        color: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({}),
  pageType: PropTypes.string
};
