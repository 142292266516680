/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
// import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./audio-magazine.m.css";
import { get } from "lodash";
import Icon from "../atoms/icon";
import ResponsiveImageWithFallback from "../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../utils/utils";
import { connect } from "react-redux";
import assetify from "@quintype/framework/assetify";
import loader from "../../../assets/images/loader.gif";
import { LoadmoreAudiostories } from "../../api";
class AudioMagazineCollection extends React.Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      currentStoryId: null,
      playingAll: false,
      visibleItems: [], // Stories that are currently visible
      offset: 0, // Start with an initial offset of 0 (will update with preloaded stories)
      limit: 10, // Number of stories to fetch per API request
      loading: false, // Track the loading state
      hasMoreStories: false, // Whether there are more stories to load
      collectionName: ""
    };
  }
  componentDidMount() {
    const { data } = this.props;
    const initialStories = get(data, ["collection", "items"], []);
    const totalStoryCount = get(data, ["collection", "total-count"], 0);
    const collectionName = get(data, ["collection", "slug"], "");

    // Initialize visibleItems with preloaded stories and set initial offset based on the number of preloaded stories
    this.setState({
      visibleItems: initialStories,
      totalStoryCount: totalStoryCount,
      offset: initialStories.length, // Offset is the number of initially loaded stories
      hasMoreStories: initialStories.length < totalStoryCount,
      collectionName: collectionName
      // Check if there are more stories to load
    });
  }

  componentDidUpdate(prevProps) {
    const { currentPlayingStory, PlayAudio } = this.props;

    // Check if PlayAudio has changed or if the current story has changed
    if (
      PlayAudio !== prevProps.PlayAudio ||
      (currentPlayingStory && currentPlayingStory.id !== this.state.currentStoryId)
    ) {
      if (currentPlayingStory) {
        // Update the state to reflect the new story
        this.setState({
          playing: PlayAudio, // Or true/false based on PlayAudio
          currentStoryId: currentPlayingStory.id
        });
      } else {
        // Handle case where there is no currentPlayingStory
        this.setState({
          playing: false,
          currentStoryId: null
        });
      }
    }
  }

  // Function to fetch stories from the API
  fetchStories = async offset => {
    const { data } = this.props;

    const collectionslug = get(data, ["collection", "slug"], "");

    const { limit, visibleItems, collectionName } = this.state;
    const collectionId = collectionName || collectionslug; // Replace with your actual collectionId

    this.setState({ loading: true });

    LoadmoreAudiostories(collectionId, limit, offset)
      .then(res => {
        // Assuming the API response contains stories
        const newStories = res.items || [];

        this.setState(prevState => ({
          visibleItems: [...prevState.visibleItems, ...newStories], // Append new stories to existing ones
          offset: prevState.offset + limit, // Update offset for the next fetch
          loading: false, // Set loading to false
          hasMoreStories: visibleItems.length + newStories.length < prevState.totalStoryCount // Check if there are more stories to load
        }));
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false }); // Set loading to false on error
      });
  };

  // Handle loading more stories on button click
  handleLoadMore = () => {
    const { offset, hasMoreStories } = this.state;

    if (hasMoreStories) {
      this.fetchStories(offset); // Fetch the next set of stories using the updated offset
    }
  };

  handleTogglePlayCurrentStory = () => {
    const { playing, currentStoryId } = this.state;

    if (playing) {
      this.handlePause(); // Pause the currently playing story
    } else if (currentStoryId) {
      this.handlePlayById(currentStoryId); // Play the currently active story
    } else {
      this.handlePlayFirstStory(); // No story is playing, so start with the first one
    }
  };

  handlePlayFirstStory = () => {
    const { data } = this.props;
    const items = get(data, ["collection", "items"], []); // Get the stories from the collection
    const firstStory = get(items, [0, "story"]); // Access the first story

    if (firstStory) {
      this.handlePlay(firstStory); // Reuse the existing handlePlay method
    }
  };

  // This method allows playing a story by ID
  handlePlayById = storyId => {
    const { items } = this.props.data.collection;
    const foundItem = items.find(item => get(item, ["story", "id"]) === storyId);
    const story = foundItem && foundItem.story ? foundItem.story : undefined;

    if (story) {
      this.handlePlay(story);
    }
  };
  handlePlay = story => {
    const storyId = get(story, ["id"]);
    this.props.setCurrentStory(story);
    this.props.setCurrentCollection(this.props.data.collection);
    this.props.setMiniPlayerVisibility(true); // Dispatching action to Redux
    this.props.setAudioplaying(true);
    this.setState({ playing: true, currentStoryId: storyId });

    if (this.props.ClosePlayer) {
      this.props.setClosePlayer(false);
    }
  };

  handlePause = story => {
    this.props.setAudioplaying(false);
    this.setState({ playing: false, currentStoryId: null });
    // props.setCurrentStory(story);
  };

  render() {
    const { data } = this.props;
    // const items = get(data, ["collection", "items"], []);
    const collectionName = get(data, ["collection", "name"], "");
    const collectionImage = get(data, ["collection", "metadata", "cover-image", "cover-image-s3-key"], "");
    const collectionImageMetaData = get(data, ["collection", "metadata", "cover-image", "cover-image-metadata"], {});
    // const totalStoryCount = get(data, ["collection", "total-count"]);
    // const hasCollectionType = items.some(item => get(item, "type") === "collection");
    const { visibleItems, loading, hasMoreStories } = this.state;

    // if (hasCollectionType) {
    //   return null;
    // }
    return (
      <React.Fragment>
        <div className={`js-story-main-container ${`${styles["base"]}`} ${`${styles["magazine-story-page"]}`}`}>
          {visibleItems && visibleItems.length > 0 ? (
            <div className={`${styles["collection-stories-container"]}`}>
              <div className={`${styles["top-collection"]}`}>
                {/* {collectionImage && ( */}
                <div className={styles["cover-image"]}>
                  <ResponsiveImageWithFallback
                    className={styles["image-wrapper"]}
                    slug={collectionImage}
                    metadata={collectionImageMetaData}
                    alt={collectionName}
                    imgParams={{ auto: ["format", "compress"] }}
                    sources={generateImageSources(
                      { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                      { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                    )}
                  />
                </div>
                {/* )} */}
                <div>
                  <h4>{collectionName}</h4>
                  <div className={styles["collection-audio-bar"]}>
                    {/* {totalStoryCount && (
                      <div className={styles["episodes"]}>
                        <span>{totalStoryCount}</span>
                        Episodes
                      </div>
                    )} */}
                    <div />
                    <div className={styles["collection-cta-bar"]} onClick={this.handleTogglePlayCurrentStory}>
                      {this.state.playing ? (
                        <Icon type="icon-pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                      ) : (
                        <Icon type="icon-play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                      )}
                      {/* <Icon type="icon-play" className={`${styles["play"]} ${styles["audio-icon"]}`} /> */}
                      <span className={styles["play-episodes"]}>
                        {this.state.playing ? "Pause Episode" : "Play Episodes"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {visibleItems.map((item, index) => {
                  const headline = get(item, ["story", "headline"], "");
                  const heroImageKey = get(item, ["story", "hero-image-s3-key"], "");
                  // const heroImageUrl = `https://gumlet.assettype.com/${heroImageKey}`;
                  const story = get(item, ["story"]);
                  const storyId = get(item, ["story", "id"]);
                  const isCurrentStory = this.state.currentStoryId === storyId;

                  // console.log("headline", headline);

                  return (
                    <div
                      key={index}
                      className={styles["item-container"]}
                      onClick={
                        isCurrentStory && this.state.playing
                          ? () => this.handlePause(story)
                          : () => this.handlePlay(story)
                      }
                    >
                      <div>
                        {/* <img src={heroImageUrl} alt={headline} className={styles["hero-image"]} /> */}
                        <ResponsiveImageWithFallback
                          className={styles["image-wrapper"]}
                          slug={heroImageKey}
                          metadata={headline}
                          alt={headline}
                          imgParams={{ auto: ["format", "compress"] }}
                          sources={generateImageSources(
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                          )}
                        />
                      </div>
                      <div className={styles["audio-story-content"]}>
                        <h5>{headline}</h5>
                        <div className={styles["audio-controls-top-section"]}>
                          <div className={`${styles["audio-control-icons"]}`}>
                            {isCurrentStory && this.state.playing ? (
                              <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                            ) : (
                              <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Load More Button */}
              {hasMoreStories && (
                <button onClick={this.handleLoadMore} className={styles["load-more-button"]} disabled={loading}>
                  {loading ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          ) : (
            <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazineCollection.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};

function mapDispatchToProps(dispatch) {
  return {
    setMiniPlayerVisibility: isVisible => {
      dispatch({
        type: "SET_MINI_PLAYER_VISIBILITY",
        payload: isVisible
      });
    },
    setAudioplaying: isVisible => {
      dispatch({
        type: "AUDIO_PLAYING",
        payload: isVisible
      });
    },
    setCurrentStory: story => {
      dispatch({
        type: "CURRENT_STORY",
        payload: story
      });
    },
    setCurrentCollection: collection => {
      dispatch({
        type: "CURRENT_COLLECTION",
        payload: collection
      });
    },
    setClosePlayer: isVisible => {
      dispatch({
        type: "CLOSE_PLAYER",
        payload: isVisible
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    data: get(state, ["qt", "data"]),
    enablePlayer: get(state, ["ShowPlayer"]),
    PlayAudio: get(state, ["PlayAudio"]),
    ClosePlayer: get(state, ["ClosePlayer"]),
    currentPlayingStory: get(state, ["CurrentStory"])
  };
}

const AudioMagazineCollectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioMagazineCollection);

export { AudioMagazineCollectionPage as AudioMagazineStoryPage };
