import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { MagazineCard } from "../../../../../atoms/magazine-buy-card";
import { fetchCollection } from "../../../../../../api";
import { get } from "lodash";

const AudioMagazineListCard = ({ collection, audioMagazine = true, utmURL = "", config }) => {
  const collectionSlug = "home-magazine";
  const pageNumber = 0;
  const limit = 7;
  const [stories, setData] = useState([]);
  const [filteredStories, setFilteredStories] = useState([]);

  const allowedMagazines = ["ஆனந்த விகடன்", "நாணயம் விகடன்", "ஜூனியர் விகடன்"];

  useEffect(() => {
    fetchCollection(collectionSlug, pageNumber, limit, {}, "collection").then(response => setData(response.items));
  }, []);

  // Filter the data
  useEffect(
    () => {
      const filteredItems = stories.filter(item => {
        const magazines = get(item, ["metadata", "entities", "collectionEntities", "magazine"], []);
        const magazineNames = magazines.map(magazine => magazine.name);
        return magazineNames.some(name => allowedMagazines.includes(name));
      });

      setFilteredStories(filteredItems);
    },
    [stories]
  );

  let stacks =
    filteredStories &&
    filteredStories.map((item, index) => {
      const magazineEntityId = get(item, ["metadata", "entities", "collectionEntities", "magazine", 0, "id"]);
      const magazineConfig = config && config.magazines && config.magazines.find(m => m.id === magazineEntityId);
      const magazinePath = get(magazineConfig, ["url"], "");
      item.pagePath = magazinePath;

      return (
        <MagazineCard
          collection={item}
          key={index}
          className={styles.card}
          audioMagazine={audioMagazine}
          utmURL={utmURL}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }
  return (
    <div className={`${styles["base"]}`}>
      <h4 className={styles["title"]}>Audio Magazine</h4>
      <div className={styles["carousel-wrapper"]}>{stacks}</div>
    </div>
  );
};

AudioMagazineListCard.propTypes = {
  audioMagazine: PropTypes.bool,
  utmURL: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  config: PropTypes.shape()
};

export default AudioMagazineListCard;
