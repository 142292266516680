import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import SevenStory7s from "../seven-story-7s";
import { VikatanMainResponsive } from "../ad-free";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
import StoryCardCurve from "../../atoms/story-card-curve";

const EighteenStoriesOneAd = ({ collection, accentColor, pagetype = "" }) => {
  let stories = get(collection, ["items"], [])
    .filter(item => item.type === "story")
    .map(item => item.story)
    .filter(story => getStoryHeadline(story) && story.url);
  let schemaInc = 1;

  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div
        itemType="http://schema.org/ItemList"
        itemScope
        id="topStories"
        className={`${styles["eighteen-stories"]} ${styles.row} container row `}
      >
        <div className={styles["stories-grid"]}>
          <div className={styles["stories-grid-stories"]}>
            {stories.splice(0, 18).map((story, adIndex) => (
              <div key={adIndex} className={styles["story-card"]}>
                <StoryCardCurve key={story.id} className={styles[""]} story={story} schemaIncrement={schemaInc++} />
              </div>
            ))}
          </div>

          <div>
            <VikatanMainResponsive
              placement="section-page"
              MobileAdtype="Vikatan_mrec_mid_SP"
              DesktopAdtype="Vikatan_Desktop_SP_ATF_Right_300x250"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

EighteenStoriesOneAd.propTypes = {
  accentColor: PropTypes.string,
  collection: SevenStory7s.propTypes.collection,
  pagetype: PropTypes.string
};
EighteenStoriesOneAd.storyLimit = 9;

export default EighteenStoriesOneAd;
