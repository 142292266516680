import React from "react";
import PropTypes from "prop-types";

import styles from "./webstories.m.css";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import WCScoreTable from "../rows/wc-score-table";

export class WCDetailPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
  }

  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <div
          className={`main-page styles-m__base__2vtxy js-story-main-container ${`${styles["base-class"]} ${
            styles["worldcup-page"]
          } ${styles["worldcup-page-details"]}`}`}
        >
          <div className="page-content infinity-page">
            <div className={styles["cricekt-header"]}>
              <ul>
                <li>
                  <a href="/cricket/icc-world-cup-2023" target="_blank">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/cricket/icc-world-cup-2023" target="_blank">
                    Play Quiz
                  </a>
                </li>
                <li>
                  <a href="/cricket/icc-world-cup-2023" target="_blank">
                    Points Table
                  </a>
                </li>
                <li>
                  <a href="/cricket/icc-world-cup-2023" target="_blank">
                    Worldcup Updates
                  </a>
                </li>
              </ul>
            </div>
            <div className={`container ${styles["story-grid"]}`}>
              <div className={styles["banner-sec"]}>
                <div className={styles["banner-logo-sec"]}>
                  <div className={styles["sathya-logo"]}>
                    <a
                      href="https://www.sathya.store/?utm_source=Eywa&amp;utm_medium=QR_Code&amp;utm_campaign=Sathya_Website_Vikatan_Ad"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://gumlet.assettype.com/vikatan/2023-10/7996ea79-c41f-4a07-92d6-94771d0baa12/sathya_logo_large.png?format=webp&w=200&dpr=1.0"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className={styles["sporta-logo"]}>
                    <img
                      src="https://gumlet.assettype.com/vikatan/2023-10/aa815208-4e9c-4aef-8974-1d7d6646cc2e/sports_vikatan.png?format=webp&w=120&dpr=1.0"
                      alt=""
                    />
                  </div>
                </div>
                <div className={styles["presents"]}>Presents</div>
              </div>
              <WCScoreTable slug={data.slug} />
            </div>
          </div>

          <div />
        </div>
      </React.Fragment>
    );
  }
}

WCDetailPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string
};
