import React from "react";
import PropTypes from "prop-types";

import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./olympics.m.css";
import OlympicsYoutubeVideoGallery from "./story/OlympicsYoutubeReels";
import OlympicsIndiaMedalTable from "../rows/olympics-medal-2024";
import OlympicsIndiaMedalPointsTable from "../rows/olympics-medal-points-table-2024";
import Cookies from "universal-cookie";
const newTemplates = addWithoutAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class OlympicsPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  handleFilter = (slug, index) => {
    this.setState({ filter: slug, currentIndex: index, action: false });
    setTimeout(() => this.setState({ action: true }), 500);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  handleFilter = slug => {
    this.setState({ filter: slug });

    setTimeout(() => {
      if (slug === "Play Quiz" && this.iframeWidgetsRef.current) {
        const iframeWidgetsTop = this.iframeWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: iframeWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Points Table" && this.pointtableWidgetsRef.current) {
        const pointtableWidgetsTop = this.pointtableWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: pointtableWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Worldcup Updates" && this.StoryCollectionWidgetsRef.current) {
        const StoryCollectionWidgetsTop = this.StoryCollectionWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: StoryCollectionWidgetsTop,
          behavior: "smooth"
        });
      }
    }, 500);
  };

  handleSharePage = () => {
    if (navigator.share) {
      const sharedText =
        "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆 \n \n Click here to Participate:";
      navigator
        .share({
          title:
            "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆",
          text: sharedText,
          url: window.location.href
        })
        .then(() => {
          console.log("Successfully shared");
          this.hanldeAction("more share");
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  render() {
    const cookies = new Cookies();
    const vuid = cookies.get("vuid");
    return (
      <React.Fragment>
        <div
          className={`main-page styles-m__base__2vtxy js-story-main-container ${`${styles["base-class"]} ${
            styles["worldcup-page"]
          }`}`}
        >
          <div className="page-content infinity-page">
            {/* <div className={`${styles["cricekt-header"]}`}>
              <ul>
                <li onClick={() => this.handleFilter("Olympics Updates")}>ஒலிம்பிக் அப்டேட்ஸ்</li>
                <li onClick={() => this.handleFilter("Gold Medals")}>பதக்கப்பட்டியல்</li>
                <li onClick={() => this.handleFilter("Videos")}>வீடியோஸ்</li>
                <li onClick={() => this.handleFilter("Terms & conditions")}>Terms & conditions</li>
              </ul>
            </div> */}
            <div className={`${styles["ipl-banner-section"]}`}>
              <div className={`${styles["container"]} container`}>
                <div className={styles["olympics-banner"]}>
                  <div className={styles["olympics-logo"]}>
                    <img src="https://gumlet.assettype.com/vikatan/2024-07-31/nlsqo9xr/olympic.png" alt="" />
                    <div className={`${styles["olymplics-dates"]} hidden-desktop`}>
                      <img
                        src="https://gumlet.assettype.com/vikatan/2024-08/c7d9055b-5ab4-49e5-9aeb-df745256fd43/olympics-dates.png?format=webp&w=470pr=1.0"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles["banner-sec"]}>
                      <div className={styles["banner-logo-sec"]}>
                        <div className={styles["sathya-logo"]}>
                          <a
                            href="https://www.sathya.store/?utm_source=Eywa&amp;utm_medium=QR_Code&amp;utm_campaign=Sathya_Website_Vikatan_Ad"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              src="https://gumlet.assettype.com/vikatan/2024-06/834360b7-394d-4a9a-a027-484920037c45/wc-sathya-logo.png?format=webp&w=200&dpr=1.0"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className={styles["sporta-logo"]}>
                          <img
                            src="https://gumlet.assettype.com/vikatan/2023-10/aa815208-4e9c-4aef-8974-1d7d6646cc2e/sports_vikatan.png?format=webp&w=120&dpr=1.0"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className={styles["presents"]}>Presents</div>
                    </div>
                    <div className="hidden-mobile">
                      <img
                        src="https://gumlet.assettype.com/vikatan/2024-08/c7d9055b-5ab4-49e5-9aeb-df745256fd43/olympics-dates.png?format=webp&w=470pr=1.0"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles["price-list-wrapper"]}>
              <div className={`${styles["container"]} container`}>
                <h1>Participate in Quiz and Win exciting prizes*</h1>
                <div className={styles["price-list"]}>
                  <div>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/350235db-c849-4c7d-8d5d-d8b1def3a6af/olympics-1st-price.png" />
                  </div>
                  <div>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/32d389ba-5428-4c7c-8c61-a4da66c20149/olympics-2nd-price.png" />
                  </div>
                  <div>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/93feeb5f-f775-4a96-854c-f04f84978f1a/olympics-3rd-price.png" />
                  </div>
                </div>
                <div>
                  <img src="https://gumlet.assettype.com/vikatan/2024-08/2836a9f3-686d-4005-b29e-89750078dad2/olympics-consolidate-price.png" />
                </div>
              </div>
            </div>

            <div className={styles["iframe-wrapper"]}>
              <div className={`container ${styles["story-grid"]}`}>
                <div className={`${styles["iframe-widgets-wc"]} container`} ref={this.iframeWidgetsRef}>
                  <div className={styles["iframe-widget-quiz"]}>
                    {vuid ? (
                      <div>
                        <iframe src="https://special.vikatan.com/olympic-quiz-poll/" alt="" />
                      </div>
                    ) : (
                      <div>
                        <a
                          href="https://login.vikatan.com?redirect_url=https://sports.vikatan.com/olympics"
                          className={styles["login-img"]}
                        >
                          <img src="https://gumlet.assettype.com/vikatan/2024-08/11956396-5f4c-434a-b014-49645d24ba52/Vikatan_Sathya_Paris_olympics_Quiz_Banner.png" />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={styles["iframe-widget-scoreboard"]}>
                    <iframe src="https://special.vikatan.com/olympic-quiz-poll/scoreboard.php" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles["medal-top-table"]}>
              <OlympicsIndiaMedalTable title="இந்தியாவின் பதக்கப் பட்டியல்" titleClassName="white-theme" />
            </div>

            <div className={styles["olympics-stories"]}>
              <div className={`container ${styles["story-grid"]}`}>
                <div className={styles["main-content"]} ref={this.StoryCollectionWidgetsRef}>
                  <LazyCollection
                    collection={this.props.data.collection}
                    config={this.props.config}
                    collectionTemplates={getAdsWrappedCollectionTemplate}
                    lazyAfter={1}
                    accentColor={"#2f81cd"}
                    pageType={"cricket"}
                  />
                </div>
                <OlympicsYoutubeVideoGallery title="பாரிஸ் ஒலிம்பிக்ஸ் 2024 வீடியோஸ்" titleClassName="white-theme" />
              </div>
            </div>
            <div className={styles["medal-top-table"]}>
              <OlympicsIndiaMedalPointsTable title="ஒலிம்பிக்ஸ் 2024 பதக்கப்பட்டியல்" titleClassName="white-theme" />
            </div>

            <div className={styles["terms-wrapper-wc"]}>
              <div className={`container ${styles["terms-wrap-container"]}`}>
                <div className={styles["social-wrapper"]}>
                  <div className={styles["social-media-wrap"]}>
                    <h3>Follow Sathya:</h3>
                    <div>
                      <a href="https://www.facebook.com/sathya.retail" rel="noopener noreferrer" target="_blank">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/78184621-9290-492c-b851-1bcde30d0b82/ipl_fb.png"
                          alt="sathya Facebook"
                        />
                      </a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/sathya.retail/" rel="noopener noreferrer" target="_blank">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/aa228433-397b-40b8-899f-37f2840aa875/ipl_instagram.png"
                          alt="sathya Instagram"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://adtarbo.eywamedia.com/qr/scan?c=6529256630383"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/621beace-7c5a-4ed0-a5f7-02a0d1634035/ipl_whatsapp.png"
                          alt="sathya Whatsapp"
                        />
                      </a>
                    </div>
                  </div>
                  <div className={styles["social-media-wrap"]}>
                    <h3>Follow Vikatan: </h3>
                    <div>
                      <a href="https://www.facebook.com/SportsVikatan/" rel="noopener noreferrer" target="_blank">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/78184621-9290-492c-b851-1bcde30d0b82/ipl_fb.png"
                          alt="Vikatan Facebook"
                        />
                      </a>
                    </div>
                    <div>
                      <a href="https://www.instagram.com/sportsvikatan/" rel="noopener noreferrer" target="_blank">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/aa228433-397b-40b8-899f-37f2840aa875/ipl_instagram.png"
                          alt="Vikatan Instagram"
                        />
                      </a>
                    </div>
                    <div>
                      <a href="https://twitter.com/sportsvikatan" rel="noopener noreferrer" target="_blank">
                        <img
                          src="https://gumlet.assettype.com/vikatan/2024-03/2da849cb-adcd-45c1-8769-3ecf3421f303/ipl_twitter.png"
                          alt="Vikatan Twitter"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className={styles["terms-points"]}>
                  <h2>
                    <a
                      href="https://www.vikatan.com/cricket-quiz-terms-and-conditions"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <span>*</span> Terms and Conditions
                    </a>
                  </h2>
                  <div className={styles["terms-list"]}>
                    <h3>Disclaimer:</h3>
                    <p>
                      1. By participating in the &quot;Sixer Adi, iPhone Ready&quot; (&quot;Contest&quot;), you
                      acknowledge and agree to abide by the following Terms and Conditions*. Vikatan reserves the right
                      to modify or update these terms* at any time without prior notice.
                    </p>
                    <p>
                      2. The Contest is organized solely for entertainment and educational purposes. It is not
                      associated with, endorsed by, or affiliated with any specific sports organization, team, or
                      governing body.
                    </p>
                    <p>
                      3. Only one participant per family is allowed. Any violation of this rule may result in
                      disqualification of the contestant(s) from receiving the final prizes, at the discretion of the
                      authority.
                    </p>
                    <p>
                      4. By participating in this contest you agree to be bound by the Terms and conditions provided in
                      the Link. If you do not agree to with any part of the terms, please do not participate.
                    </p>
                  </div>
                </div> */}
              </div>
              <div className={styles["copyrights"]}>© vikatan 2024. All Rights Reserved</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OlympicsPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
