import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styles from "./styles.m.css";

export const IPLMatchCard = ({ item, className = "", status }) => {
  const relatedName = get(item, ["match_no"], " ");
  const winner = get(item, ["winner"], "");
  const teamsA = get(item, ["team1"], " ");
  const teamsB = get(item, ["team2"], " ");
  const matchDate = get(item, ["match_date"], "");

  const teamsAlogo = get(item, ["team1_logo"], " ");
  const teamsBlogo = get(item, ["team2_logo"], " ");
  const inningsB = get(item, ["innings", "b_1", "run_str"], " ");
  const locationName = get(item, ["venue", "city"], "");

  return (
    <React.Fragment>
      <div className={styles["ipl-card"]}>
        <div className={styles["shortname"]}>
          <div className={styles["headline"]}>
            <span>{relatedName} Match</span>
            <span className={styles["formattedtime"]}>{`${matchDate}`}</span>{" "}
          </div>
        </div>
        <div className={styles["ipl-card-grid"]}>
          <ul className={styles["ipl-score-list"]}>
            <li className={styles["ipl-score"]}>
              <div className={`${styles["image-view"]}`}>
                <img src={teamsAlogo} />
              </div>
              <div className={styles["team-name"]}>{teamsA}</div>
              {/* <div className={styles["ipl-score-pnts"]}>
                  <span>{inningsA}</span>
                </div> */}
            </li>
          </ul>
          <div className={styles["match-name"]}>
            <div className={styles["match-name-tag"]}>
              {status === "not_started" && (
                <div>
                  <p>
                    <span className={styles["score-tag-notstarted"]}>Upcoming</span>
                  </p>
                </div>
              )}
              {status === "completed" && (
                <div>
                  <p>
                    <span className={styles["score-tag-completed"]}>{status}</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <ul className={styles["ipl-score-list"]}>
            <li className={styles["ipl-score"]}>
              <div className={`${styles["image-view"]}`}>
                <img src={teamsBlogo} />
              </div>
              <div>{teamsB}</div>
              <div className={styles["ipl-score-pnts"]}>
                <span>{inningsB}</span>
              </div>
            </li>
          </ul>
        </div>
        {winner ? (
          <div className={styles["winner-status"]}>{winner}</div>
        ) : (
          <div className={styles["winner-status"]}>{locationName}</div>
        )}
      </div>
    </React.Fragment>
  );
};

IPLMatchCard.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  item: PropTypes.shape()
};

export default IPLMatchCard;
