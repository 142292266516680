import React from "react";
import PropTypes from "prop-types";
import { Link, WithHostUrl } from "@quintype/components";
import styles from "./styles.m.css";
import Icon from "../../../../../atoms/icon";

function getSectionPath(section, parentSection) {
  if (section["section-url"]) {
    return section["section-url"];
  }

  if (parentSection) {
    return `/${parentSection.slug}/${section.slug}`;
  }

  return `/${section.slug}`;
}

const Breadcrumb = ({ subSection, config, className = "", collection, story }) => {
  let parentSection = null;
  let storySlug = null;
  if (subSection) {
    parentSection = config.sections ? config.sections.find(section => section.id === subSection["parent-id"]) : null;
  }
  if (story) {
    if (collection && (collection.slug === "gurupeyarchi" || collection.slug === "sanipeyarchi")) {
      const lastPartOfSlug = story.slug.split("/").reverse()[0];
      storySlug = `/horoscope/${collection.slug}/${lastPartOfSlug}`;
    } else {
      storySlug = story.url || story.slug;
    }
  }
  let linkPosition = 1;
  return (
    <ul className={`${styles.breadcrumbs} ${className}`} itemScope itemType="http://schema.org/BreadcrumbList">
      <li className={styles.breadcrumb} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
        <WithHostUrl>
          {({ primaryHostUrl }) => (
            <Link aria-label={config["publisher-name"]} href={primaryHostUrl} itemprop="item">
              <span>
                <Icon type="home" />
              </span>
              <span itemProp="name" style={{ display: "none" }}>
                Vikatan
              </span>
            </Link>
          )}
        </WithHostUrl>
        <meta itemProp="position" content={linkPosition} />
      </li>
      {parentSection && (
        <li className={styles.breadcrumb} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link
            aria-label={`${"Navigate to "} ${parentSection.name}`}
            href={getSectionPath(parentSection)}
            itemprop="item"
          >
            <span itemProp="name">{parentSection.name}</span>
          </Link>
          <meta itemProp="position" content={++linkPosition} />
        </li>
      )}
      {subSection && (
        <li className={styles.breadcrumb} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link
            aria-label={`${"Navigate to "} ${subSection.name}`}
            href={getSectionPath(subSection, parentSection)}
            itemprop="item"
          >
            <span itemProp="name">{subSection.name}</span>
          </Link>
          <meta itemProp="position" content={++linkPosition} />
        </li>
      )}
      {collection && (
        <li className={styles.breadcrumb} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link aria-label={`${"Navigate to "} ${collection.name}`} href={`${collection.pagePath}`} itemprop="item">
            <span itemProp="name">{collection.slug}</span>
          </Link>
          <meta itemProp="position" content={++linkPosition} />
        </li>
      )}
      {story && (
        <li className={styles.breadcrumb} itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link aria-label={`${"Navigate to "} ${story["custom-slug"]}`} href={storySlug} itemprop="item">
            <span itemProp="name">{story["custom-slug"]}</span>
          </Link>
          <meta itemProp="position" content={++linkPosition} />
        </li>
      )}
    </ul>
  );
};

Breadcrumb.propTypes = {
  config: PropTypes.shape({
    section: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    "publisher-name": PropTypes.string
  }),
  subSection: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string
  }),
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  }),
  story: PropTypes.shape({
    id: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        "contributor-role": PropTypes.shape({
          name: PropTypes.string
        }),
        name: PropTypes.string
      })
    ),
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
    alternative: PropTypes.shape({
      home: PropTypes.shape({
        default: PropTypes.shape({
          headline: PropTypes.string
        })
      })
    })
  })
};

export default Breadcrumb;
