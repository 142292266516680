import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { LoadMoreStoriesBase } from "@quintype/components";
import { WebEngage, GA, PGAD } from "../../../integrations";
import { LoadMoreButton } from "../../atoms/read-more-link";
import StoryCardML4x3DP1x1 from "../../atoms/story-card-ml4x3-dl1x1";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";
import { DfpAd } from "../../atoms/dfp-ad";
import { FIELDS_FOR_LISTING_PAGE, PAGE_TYPE } from "../../../constants";

import styles from "./styles.m.css";

const StoryList = ({ stories }) =>
  stories.length > 0 ? (
    <React.Fragment>
      {stories.map((story, index) => {
        return (
          <React.Fragment key={story.id}>
            {index % 3 === 0 && (
              <DfpAd
                className={`${styles["mobile-leaderboard-ad"]} hidden-desktop`}
                adtype="Mobile_Leaderboard"
                placement="special-topic-page"
              />
            )}
            <StoryCardML4x3DP1x1 story={story} className={styles["story-card"]} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  ) : (
    <h3 className={styles["no-results"]}>No stories found.</h3>
  );

StoryList.propTypes = {
  stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story)
};

const StoryListBase = ({ stories, loading, onLoadMore, noMoreStories }) => (
  <React.Fragment>
    <StoryList stories={stories} />
    {!noMoreStories &&
      stories.length >= 8 && (
        <div className={styles["load-more-wrap"]}>
          <LoadMoreButton text={loading ? "LOADING..." : " மேலும் படிக்க"} disabled={loading} onClick={onLoadMore} />
        </div>
      )}
  </React.Fragment>
);

export { StoryListBase };

StoryListBase.propTypes = {
  stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story),
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  noMoreStories: PropTypes.bool
};

const SpecialTopicPage = ({ data }) => {
  const entityName = get(data, ["entity", "tamil-name"]);
  const entityContent = get(data, ["entity", "content"]);
  const entityImage = get(data, ["entity", "photo", 0]);
  const [isTopicOpen, setIsTopicOpen] = React.useState(false);
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.TOPIC_PAGE, {
      entity: get(data, ["entity"], {})
    });
    PGAD.loadScript();
  }, []);
  const handleTopicOpenToggleClick = () => {
    setIsTopicOpen(!isTopicOpen);
  };

  return (
    <div className={`main-page ${styles["special-topic-page-container"]}`}>
      <div />
      <div className={styles.base}>
        <DfpAd
          className={`${styles["mobile-leaderboard-ad"]} hidden-desktop`}
          adtype="Mobile_Leaderboard"
          placement="special-topic-page"
        />
        <div className="row container">
          <div className={`${styles.header}`}>
            {entityName && <h1 className={`hidden-desktop ${styles["topic-headline"]}`}>{entityName}</h1>}
            <div className={styles["topic-content"]}>
              {entityImage && (
                <div className={styles["topic-image-wrapper"]}>
                  <ResponsiveImageWithFallback
                    className={styles["topic-image"]}
                    slug={entityImage.key}
                    metadata={{}}
                    alt={entityName}
                    imgParams={{ auto: ["format", "compress"] }}
                    sources={generateImageSources({
                      aspectRatio: [3, 4],
                      screenWidthCoverage: 0.3
                    })}
                  />
                </div>
              )}
              {entityName && <h1 className={`hidden-mobile ${styles["topic-headline"]}`}>{entityName}</h1>}
              {entityContent && (
                <div className={`${styles["topic-content-text"]} ${isTopicOpen ? styles["is-open"] : ""}`}>
                  <div dangerouslySetInnerHTML={{ __html: entityContent }} />
                  {!isTopicOpen && (
                    <LoadMoreButton
                      className={styles["topic-load-more-button"]}
                      text="மேலும் படிக்க"
                      onClick={handleTopicOpenToggleClick}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles["story-list"]}>
            <LoadMoreStoriesBase
              api="/api/v1/advanced-search"
              apiResponseTransformer={response => response.items}
              template={StoryListBase}
              numStoriesToLoad={9}
              data={data}
              params={{ q: entityName, "content-types": "story" }}
              fields={FIELDS_FOR_LISTING_PAGE}
            />
          </div>
          <div className={styles["ad-container"]}>
            <DfpAd className={styles["ad"]} adtype="Rectangle" placement="special-topic-page" />
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export { SpecialTopicPage };

SpecialTopicPage.propTypes = {
  data: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string,
      properties: PropTypes.shape({
        content: PropTypes.string,
        photo: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string
          })
        )
      })
    }),
    stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story)
  })
};
