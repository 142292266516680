import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { SearchBox, LoadMoreStoriesBase, Link } from "@quintype/components";
import { WebEngage, GA, DeepBI } from "../../../integrations";
import { StoryListBase } from "../tag/index";
import { Icon } from "../../atoms/icon";

import styles from "./styles.m.css";
import { FIELDS_FOR_LISTING_PAGE, PAGE_TYPE } from "../../../constants";
import { VikatanCollectionResponsive } from "../../rows/ad-free";

function clickEvent(kw) {
  GA.customEvent("Search", "Clicked Search", kw);
  WebEngage.pwEvent("Clicked Search", { q: kw });
  DeepBI.PingSearchClicked({ q: kw });
}
function DrawForm({ children }) {
  const searchParam = children.props.value || "";
  return (
    <React.Fragment>
      <label htmlFor="stg" className={styles["search-query"]} key={"label1"}>
        {children}
      </label>
      <button
        title="search"
        aria-label="search"
        onClick={() => clickEvent(searchParam)}
        role="button"
        className={styles["search-btn"]}
        type="submit"
        key={"btn1"}
      >
        <Icon type="search" key={"search1"} />
      </button>
    </React.Fragment>
  );
}

DrawForm.propTypes = {
  children: PropTypes.element
};

class SearchPage extends React.Component {
  constructor() {
    super();
    this.state = {
      showSortDropdown: false,
      showFilterDropdown: false
    };
    this.handleSortOptionsDropdownClick = this.handleSortOptionsDropdownClick.bind(this);
    this.handleFilterOptionsDropdownClick = this.handleFilterOptionsDropdownClick.bind(this);
  }

  componentDidMount() {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.SEARCH_PAGE);
    DeepBI.registerPageView({ collection: this.props });
  }

  getPath(key, value) {
    const newParams = { ...this.props.data.params, [key]: value };
    return (
      "/search?" +
      Object.keys(newParams)
        .map(key => `${key}=${newParams[key]}`)
        .join("&")
    );
  }

  renderFilterDropdown() {
    return (
      <div className={styles.dropdown}>
        <button className={styles["dropdown-button"]} onClick={this.handleFilterOptionsDropdownClick}>
          Filter By {this.props.data.params["type"] || "All"} (
          {get(this.props.data, ["facets", this.props.data.params["type"]]) || this.props.data.total})
          <Icon type="filter" className={styles["filter-icon"]} />
        </button>
        {this.state.showFilterDropdown && (
          <div className={styles["dropdown-items"]}>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["type"] === "all" ? styles["selected"] : ""
              }`}
              href={this.getPath("type", "all")}
            >
              All ({this.props.data.total || 0})
            </Link>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["type"] === "text" ? styles["selected"] : ""
              }`}
              href={this.getPath("type", "text")}
            >
              Text ({get(this.props.data, ["facets", "text"]) || 0})
            </Link>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["type"] === "photo-album" ? styles["selected"] : ""
              }`}
              href={this.getPath("type", "photo-album")}
            >
              Album ({get(this.props.data, ["facets", "photo-album"]) || 0})
            </Link>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["type"] === "video" ? styles["selected"] : ""
              }`}
              href={this.getPath("type", "video")}
            >
              Video ({get(this.props.data, ["facets", "video"]) || 0})
            </Link>
          </div>
        )}
      </div>
    );
  }

  renderSortDropdown() {
    return (
      <div className={styles.dropdown}>
        <button className={styles["dropdown-button"]} onClick={this.handleSortOptionsDropdownClick}>
          Sort By {this.props.data.params["sort"] === "latest-published" ? "Date" : "Relevance"}
          <Icon type="sort" className={styles["sort-icon"]} />
        </button>
        {this.state.showSortDropdown && (
          <div className={styles["dropdown-items"]}>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["sort"] === "latest-published" ? styles["selected"] : ""
              }`}
              href={this.getPath("sort", "latest-published")}
            >
              Date
            </Link>
            <Link
              className={`${styles["dropdown-item"]} ${
                this.props.data.params["sort"] === "score" ? styles["selected"] : ""
              }`}
              href={this.getPath("sort", "score")}
            >
              Relevance
            </Link>
          </div>
        )}
      </div>
    );
  }

  renderSortOptions() {
    return (
      <div className={styles["sort-options"]}>
        <span className={styles["sort-label"]}>Sort By:</span>
        <Link
          className={`${styles["sort-link"]} ${
            this.props.data.params["sort"] === "latest-published" ? styles["selected"] : ""
          }`}
          href={this.getPath("sort", "latest-published")}
        >
          Date
        </Link>
        <Link
          className={`${styles["sort-link"]} ${this.props.data.params["sort"] === "score" ? styles["selected"] : ""}`}
          href={this.getPath("sort", "score")}
        >
          Relevance
        </Link>
      </div>
    );
  }

  renderFilterOptions() {
    return (
      <div className={styles["filter-options"]}>
        <Link
          className={`${styles["filter-link"]} ${
            !this.props.data.params["type"] || this.props.data.params["type"] === "all" ? styles["selected"] : ""
          }`}
          href={this.getPath("type", "all")}
        >
          All ({this.props.data.total || 0})
        </Link>
        <Link
          className={`${styles["filter-link"]} ${this.props.data.params["type"] === "text" ? styles["selected"] : ""}`}
          href={this.getPath("type", "text")}
        >
          Text ({get(this.props.data, ["facets", "text"]) || 0})
        </Link>
        <Link
          className={`${styles["filter-link"]} ${
            this.props.data.params["type"] === "photo-album" ? styles["selected"] : ""
          }`}
          href={this.getPath("type", "photo-album")}
        >
          Album ({get(this.props.data, ["facets", "photo-album"]) || 0})
        </Link>
        <Link
          className={`${styles["filter-link"]} ${this.props.data.params["type"] === "video" ? styles["selected"] : ""}`}
          href={this.getPath("type", "video")}
        >
          Video ({get(this.props.data, ["facets", "video"]) || 0})
        </Link>
      </div>
    );
  }

  handleFilterOptionsDropdownClick() {
    this.setState({ showFilterDropdown: !this.state.showFilterDropdown, showSortDropdown: false });
  }

  handleSortOptionsDropdownClick() {
    this.setState({ showSortDropdown: !this.state.showSortDropdown, showFilterDropdown: false });
  }

  render() {
    let params = this.props.data.params || {};
    if (params.type === "all") {
      delete params.type;
    }
    params["content-types"] = "story";
    let dataValue = this.props.data.query;
    dataValue = dataValue.replace(/(<([^>]+)>)/gi, "");
    dataValue = dataValue.replace(/[^a-zA-Z ]/g, "");
    return (
      <div className={`main-page ${styles["base-container"]}`}>
        <div />
        <div className={styles.base}>
          <div className={`container`}>
            <h1 className="sr-only">Search</h1>
            <div className={styles["search-input-wrap"]}>
              <SearchBox template={DrawForm} placeholder={"Search"} initValue={dataValue} inputId="stg" />
            </div>
            <div className={styles["sort-facets"]}>
              <div className="hidden-desktop">
                {this.renderSortDropdown()}
                {this.renderFilterDropdown()}
              </div>
              <div className={`hidden-mobile ${styles["sort-facets-desktop"]}`}>
                {this.renderSortOptions()}
                {this.renderFilterOptions()}
              </div>
            </div>
            <div className={`row`}>
              <LoadMoreStoriesBase
                api="/api/v1/advanced-search"
                apiResponseTransformer={result => result.items}
                template={StoryListBase}
                numStoriesToLoad={9}
                data={this.props.data}
                fields={FIELDS_FOR_LISTING_PAGE}
                params={params}
              />
            </div>
            <div className={styles["search-ad"]}>
              <VikatanCollectionResponsive
                DesktopAdtype="Vikatan2023_Desktop_SP_BTF_728x90"
                MobileAdtype="Vikatan2023_Mobile_SP_TOP_320x100"
                placement="bundle"
              />
            </div>
          </div>
        </div>
        <div />
      </div>
    );
  }
}

export { SearchPage };

SearchPage.propTypes = {
  data: PropTypes.shape({
    stories: StoryListBase.propTypes.stories,
    total: PropTypes.number,
    query: PropTypes.string,
    params: PropTypes.shape({
      q: PropTypes.string,
      type: PropTypes.string,
      sort: PropTypes.string
    }),
    facets: PropTypes.shape({
      "photo-album": PropTypes.number,
      text: PropTypes.number,
      video: PropTypes.number
    })
  })
};
