import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Carousel from "../../atoms/carousel";

import get from "lodash/get";
import { fetchIPLMatchList } from "../../../api";
import IPLMatchCard from "../../atoms/ipl-match-card";
import styles from "./styles.m.css";

const IPLMatchList = ({
  numberOfCardsInViewDesktop = 3,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 1,
  accentColor,
  homeMatchlist,
  titleClassName,
  title
}) => {
  const desktopGap = 30;
  const mobileGap = 0;

  const [completedMatch, setcompletedMatch] = useState([]);
  const [upcomigMatch, setUpcomigMatch] = useState([]);

  useEffect(() => {
    fetchIPLMatchList().then(list => {
      const completedmatchData = get(list, ["completed"], []);
      const upmatchData = get(list, ["upcoming"], []);

      setcompletedMatch(completedmatchData);
      setUpcomigMatch(upmatchData);
    });
  }, []);

  return (
    <div className={styles.base}>
      <div className={`${styles["base"]} ${homeMatchlist ? styles["homematchlist"] : styles["pagematchlist"]}`}>
        <div className={`container ${styles["carousel-wrapper"]}`}>
          {completedMatch.length !== 0 && (
            <div className={styles["match-wrapper"]}>
              <h6 className={`${styles["headline"]} ${styles[titleClassName]}`}>Completed Matches</h6>
              <Carousel
                className={styles["carousel"]}
                options={{
                  type: "slider",
                  perView: numberOfCardsInViewDesktop,
                  gap: desktopGap,
                  focusAt: 0,
                  startAt: 0,
                  bound: true,
                  breakpoints: {
                    767: {
                      perView: numberOfCardsInViewMobile,
                      gap: mobileGap
                    },
                    991: {
                      perView: numberOfCardsInViewTablet,
                      gap: desktopGap
                    }
                  }
                }}
                renderCenterLeftControls={({ previousSlide, currentSlide }) => null}
                renderCenterRightControls={({ nextSlide }) => null}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                  return (
                    <div className={styles.dots}>
                      {completedMatch.length > 1 && (
                        <div className={styles["carousel-dots"]}>
                          {completedMatch
                            .reverse()
                            .slice(0, 6)
                            .map((item, i) => (
                              <div key={i} className={`${currentSlide === i ? styles["active-dot"] : styles["dot"]}`} />
                            ))}
                        </div>
                      )}
                    </div>
                  );
                }}
              >
                {completedMatch.length > 0 &&
                  completedMatch
                    .reverse()
                    .slice(0, 6)
                    .map((item, i) => {
                      return <IPLMatchCard key={i} item={item} className={styles.card} status="completed" />;
                    })}
              </Carousel>
            </div>
          )}
          {!homeMatchlist &&
            upcomigMatch.length !== 0 && (
              <div className={styles["match-wrapper"]}>
                <h6 className={`${styles["headline"]} ${styles[titleClassName]}`}>Upcoming Matches</h6>
                <Carousel
                  className={styles["carousel"]}
                  options={{
                    type: "slider",
                    perView: numberOfCardsInViewDesktop,
                    gap: desktopGap,
                    focusAt: 0,
                    startAt: 0,
                    bound: true,
                    breakpoints: {
                      767: {
                        perView: numberOfCardsInViewMobile,
                        gap: mobileGap
                      },
                      991: {
                        perView: numberOfCardsInViewTablet,
                        gap: desktopGap
                      }
                    }
                  }}
                  renderCenterLeftControls={({ previousSlide, currentSlide }) => null}
                  renderCenterRightControls={({ nextSlide }) => null}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                    return (
                      <div className={styles.dots}>
                        {upcomigMatch.length > 1 && (
                          <div className={styles["carousel-dots"]}>
                            {upcomigMatch.slice(0, 6).map((item, i) => (
                              <div key={i} className={`${currentSlide === i ? styles["active-dot"] : styles["dot"]}`} />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  }}
                >
                  {upcomigMatch.length > 0 &&
                    upcomigMatch
                      .slice(0, 6)
                      .map((item, i) => (
                        <IPLMatchCard key={i} item={item} className={styles.card} status="not_started" />
                      ))}
                </Carousel>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

IPLMatchList.propTypes = {
  accentColor: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  homeMatchlist: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string
};

export default IPLMatchList;
