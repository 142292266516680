import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import VikatanSixColumnGridSeries from "../../rows/magazine-6column-grid-vikatan-series/index";
import styles from "./styles.m.css";
import { WebEngage, GA, PGAD } from "../../../integrations";
import VikatanSeriesCard from "../../rows/magazine-vikatan-series-one-slider-3cards";
import Iframe from "react-iframe";

const getStoriesByLabel = collection => {
  return collection.items.filter(({ story = {} }) => story.headline && story.url).reduce((storyMap, item) => {
    const label = get(item, ["associated-metadata", "label"], "மற்றவை");
    if (storyMap[label]) {
      storyMap[label].push(item.story);
    } else {
      storyMap[label] = [item.story];
    }
    return storyMap;
  }, {});
};

const getStoriesWithLabelOnFirst = storyMap => {
  let stories = [];
  Object.keys(storyMap).forEach(key => {
    const tempStories = storyMap[key];
    if (tempStories.length) {
      tempStories[0].label = key;
    }
    stories = stories.concat(tempStories);
  });
  return stories;
};

export const SeriesLandingPage = ({ pageType, config, data: { collection, magazine, nextIssue, prevIssue } }) => {
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(pageType || "vikatan-series-page", { collection });
    PGAD.loadScript();
  }, []);
  if (!collection) return null;
  const collectionSlug = get(collection, ["slug"]);

  const storyMap = getStoriesByLabel(collection);
  const stories = getStoriesWithLabelOnFirst(storyMap);

  return (
    <React.Fragment>
      <div className={styles["base"]}>
        <div className="container">
          <div className={styles["bundle-grid"]}>
            <div className={styles["main-content"]}>
              <div className={`${styles["first-grid"]}`}>
                <VikatanSeriesCard
                  collection={collection}
                  nextIssue={nextIssue}
                  prevIssue={prevIssue}
                  config={config}
                />
              </div>
              {collectionSlug === "kanavu-series-by-suresh-sambandam" ? (
                <div className={`${styles["second-grid"]}`}>
                  <aside>
                    <div className={styles["iframe-card-container"]}>
                      <Iframe className={styles["iframe-outer"]} src="https://special.vikatan.com/kanavu_tamilagam/" />
                    </div>
                  </aside>
                </div>
              ) : null}
              <div className={`${styles["third-grid"]}`}>
                <VikatanSixColumnGridSeries stories={stories} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SeriesLandingPage.propTypes = {
  pageType: PropTypes.string,
  config: PropTypes.shape({
    contentInsightsId: PropTypes.string,
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  data: PropTypes.shape({
    oldIssues: PropTypes.arrayOf(PropTypes.any),
    config: PropTypes.shape({
      section: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string
        })
      ),
      magazines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          magazineSlug: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          vikatanMagazineId: PropTypes.number
        })
      ),
      "publisher-name": PropTypes.string
    }),
    collection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          "associated-metadata": PropTypes.shape({
            label: PropTypes.string,
            "page-no": PropTypes.string
          }),
          story: PropTypes.shape({
            id: PropTypes.string,
            contributors: PropTypes.arrayOf(
              PropTypes.shape({
                "role-name": PropTypes.string,
                authors: PropTypes.arrayOf(
                  PropTypes.shape({
                    name: PropTypes.string
                  })
                )
              })
            ),
            headline: PropTypes.string.isRequired,
            alternative: PropTypes.shape({
              home: PropTypes.shape({
                default: PropTypes.shape({
                  headline: PropTypes.string
                })
              })
            }),
            slug: PropTypes.string.isRequired,
            "hero-image-metadata": PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
              "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
            }),
            "hero-image-s3-key": PropTypes.string,
            "hero-image-caption": PropTypes.string
          })
        })
      )
    })
  })
};

export default SeriesLandingPage;
