import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchT20GroupPointTable24 } from "../../../api";

import styles from "./styles.m.css";

const T20GroupPointTable = ({ accentColor, titleClassName }) => {
  const [matchList, setData] = useState([]);

  useEffect(() => {
    fetchT20GroupPointTable24().then(list => {
      setData(list);
    });
  }, []);
  return (
    <div className={`${styles["base"]} ${styles["pagetable"]}`}>
      <div className={`${styles["point-table-wrapper"]} container`}>
        <h6 className={`${styles["point-table-title"]} ${styles[titleClassName]}`}>Team Standings (Super 8)</h6>
        {matchList &&
          Object.keys(matchList).map(groupKey => {
            const group = matchList[groupKey];
            const teams = get(group, ["teams"], []);
            return (
              <div key={groupKey} className={styles["group"]}>
                <h6 className={styles["group-title"]}>{group.group_name}</h6>
                <div className={`${styles["table-grid"]}`}>
                  <table className={styles["inner-table"]}>
                    <thead>
                      <tr>
                        <th>Team</th>
                        <th>
                          <span className={`${styles["point-dsk"]}`}>PLD</span>
                          <span className={`${styles["point-mobile"]}`}>P</span>
                        </th>
                        <th>
                          <span className={`${styles["point-dsk"]}`}>Won</span>
                          <span className={`${styles["point-mobile"]}`}>W</span>
                        </th>
                        <th>
                          <span className={`${styles["point-dsk"]}`}>Lost</span>
                          <span className={`${styles["point-mobile"]}`}>L</span>
                        </th>
                        <th>
                          <span className={`${styles["point-dsk"]}`}>Tied</span>
                          <span className={`${styles["point-mobile"]}`}>T</span>
                        </th>
                        <th>N/R</th>
                        <th>Net RR</th>
                        <th>Pts</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teams.length > 0 &&
                        teams.map((team, index) => (
                          <tr key={index}>
                            <td>
                              <div className={styles["logo-name"]}>
                                <img
                                  alt={team.short_name}
                                  src={`${team.team_logo}?format=webp&w=100&dpr=1.0`}
                                  className={styles["img-view"]}
                                />
                                <span>{team.short_name}</span>
                              </div>
                            </td>
                            <td>{team.played}</td>
                            <td>{team.won}</td>
                            <td>{team.lost}</td>
                            <td>{team.tied}</td>
                            <td>{team.no_result}</td>
                            <td>{team.net_run_rate}</td>
                            <td>{team.points}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

T20GroupPointTable.propTypes = {
  accentColor: PropTypes.string,
  titleClassName: PropTypes.string
};

export default T20GroupPointTable;
