import React from "react";
import PropTypes from "prop-types";
// import { get } from "lodash";

import { addAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI } from "../../integrations";

import styles from "./webstories.m.css";
import { VikatanAsidebar } from "../rows/ad-free";

const newTemplates = addAdsToTemplates("home");
function getAdsWrappedCollectionTemplate() {
  return newTemplates["slider-web-stories-card"];
}

export class WebstoriesPage extends React.Component {
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
  }

  render() {
    return (
      <React.Fragment>
        <div className={`main-page styles-m__base__2vtxy js-story-main-container ${styles["base-class"]}`}>
          <div />
          <div className="page-content infinity-page">
            <div className={`container ${styles["story-grid"]}`}>
              <div className={styles["main-content"]}>
                <LazyCollection
                  collection={this.props.data.collection}
                  magazine={this.props.data.magazine}
                  config={this.props.config}
                  collectionTemplates={getAdsWrappedCollectionTemplate}
                  lazyAfter={10}
                  accentColor={"#404040"}
                  numberOfCardsInViewDesktop={2}
                />
              </div>
              <aside className={styles.sidebar}>
                <VikatanAsidebar />
              </aside>
            </div>
          </div>

          <div />
        </div>
      </React.Fragment>
    );
  }
}

WebstoriesPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
