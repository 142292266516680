import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchOlympicsMedalTable24 } from "../../../api";
import styles from "./styles.m.css";

const OlympicsIndiaMedalTable = ({ accentColor, titleClassName }) => {
  const [indiaData, setIndiaData] = useState(null);

  useEffect(() => {
    fetchOlympicsMedalTable24().then(data => {
      const india = data.teams.find(team => team.id === "11");
      setIndiaData(india);
    });
  }, []);

  return (
    <div className={`${styles["base"]} ${styles["pagetable"]}`}>
      <div className={`${styles["point-table-wrapper"]} container`}>
        <h6 className={`${styles["point-table-title"]} ${styles[titleClassName]}`}>இந்தியாவின் பதக்கப் பட்டியல்</h6>
        <div className={styles["medal-table"]}>
          {indiaData ? (
            <table className={styles["inner-table"]}>
              <thead>
                <tr>
                  <th>Order</th>
                  <th />
                  <th>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/fbf6830f-b907-4a5f-8776-bf9101efb37d/olympics-gold.png" />
                    <p>Gold</p>
                  </th>
                  <th>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/a30c5ccb-7dc1-4e79-8c45-77dbfb0cd428/olympics-silver.png" />
                    <p>Silver</p>
                  </th>
                  <th>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/9b809ca3-1fa3-4e57-8926-5e141fac8c4f/olympics-bronze.png" />
                    <p>Bronze</p>
                  </th>
                  <th>
                    <img src="https://gumlet.assettype.com/vikatan/2024-08/7d0b1518-c990-4d87-9648-48731352217a/olympics-all.png" />
                    <p>All</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{indiaData.position}</td>
                  <td>{indiaData.team_name}</td>
                  <td>{indiaData.gold}</td>
                  <td>{indiaData.silver}</td>
                  <td>{indiaData.bronze}</td>
                  <td>{indiaData.total_medals}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

OlympicsIndiaMedalTable.propTypes = {
  accentColor: PropTypes.string,
  titleClassName: PropTypes.string
};

export default OlympicsIndiaMedalTable;
