import React from "react";
import PropTypes from "prop-types";
// import { get } from "lodash";

import { addAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import { DfpAd } from "../atoms/dfp-ad";

const newTemplates = addAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class VikatanSeriesPage extends React.Component {
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  render() {
    return (
      <div>
        <LazyCollection
          collection={this.props.data.collection}
          magazine={this.props.data.magazine}
          config={this.props.config}
          collectionTemplates={getAdsWrappedCollectionTemplate}
          lazyAfter={3}
          accentColor={"#2f81cd"}
        />
        <DfpAd className="hidden-mobile" adtype="Roadblock" placement="home" hidetitle={true} />
        <DfpAd className="hidden-mobile" adtype="Subscription_Lightbox" placement="home" hidetitle={true} />
        <DfpAd className="hidden-desktop" adtype="Interstitial" placement="home" hidetitle={true} />
        <DfpAd className="hidden-desktop" adtype="Mobile_lightbox" placement="home" hidetitle={true} />
      </div>
    );
  }
}

VikatanSeriesPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
