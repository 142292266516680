import React from "react";
import PropTypes from "prop-types";
import Icon from "../../atoms/icon";
import Carousel from "../../atoms/carousel";
import { connect } from "react-redux";
import get from "lodash/get";
import styles from "./styles.m.css";
import AudioCollectionMenuSlider from "../../atoms/audio-collection-menu-slider";

const AudioCategoriesCollectionCard = ({
  menu,
  numberOfCardsInViewDesktop = 9,
  numberOfCardsInViewTablet = 2,
  numberOfCardsInViewMobile = 3.5,
  accentColor
}) => {
  let menuItems = get(menu, ["items"], []);

  const desktopGap = 0;
  const mobileGap = 10;
  let stacks =
    menuItems &&
    menuItems.length > 0 &&
    menuItems.map((item, index) => {
      return (
        <AudioCollectionMenuSlider
          key={item.id}
          collection={item}
          className={styles.card}
          numberOfCardsInViewDesktop={numberOfCardsInViewDesktop}
          categoriescard={true}
        />
      );
    });

  if (!stacks || !stacks.length) {
    return null;
  }

  return (
    <div className={styles["bundle-wrapper"]}>
      <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
        <div className="hidden-mobile">
          <div className={`${styles["carousel-wrapper"]}`}>
            {stacks.length > 1 && (
              <Carousel
                className={styles["carousel"]}
                options={{
                  type: "slider",
                  perView: numberOfCardsInViewDesktop,
                  gap: desktopGap,
                  bound: true,
                  startAt: 0,
                  peek: {
                    before: 0,
                    after: 0
                  },
                  breakpoints: {
                    320: {
                      perView: numberOfCardsInViewMobile,
                      gap: mobileGap,
                      peek: {
                        before: 0,
                        after: 0
                      }
                    },
                    767: {
                      perView: numberOfCardsInViewMobile,
                      gap: mobileGap
                    },
                    991: {
                      perView: numberOfCardsInViewTablet,
                      gap: desktopGap
                    }
                  }
                }}
                renderCenterLeftControls={({ previousSlide, currentSlide }) =>
                  currentSlide > 0 ? (
                    <button
                      aria-label="previous slide"
                      role="button"
                      onClick={previousSlide}
                      className={`${styles["slider-nav-button"]}`}
                    >
                      <Icon type="angle-left" className={styles.icon} />
                    </button>
                  ) : null
                }
                renderCenterRightControls={({ nextSlide }) => (
                  <button
                    aria-label="next slide"
                    role="button"
                    onClick={nextSlide}
                    className={`${styles["slider-nav-button"]}`}
                  >
                    <Icon type="angle-right" className={styles.icon} />
                  </button>
                )}
                renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                  return (
                    <div className={styles.dots}>
                      {menuItems.map((story, index) => (
                        <button
                          title="previous slide"
                          aria-label="next slide"
                          role="button"
                          key={story.id}
                          className={
                            currentSlide === index ? `${styles["bottom-center-controls"]} ${styles["is-active"]}` : ``
                          }
                          onClick={() => {
                            goToSlide(index);
                          }}
                        />
                      ))}
                    </div>
                  );
                }}
                childClass={styles["glide_li_child"]}
              >
                {stacks}
              </Carousel>
            )}
          </div>
        </div>
        <div className="hidden-desktop">
          <div className={styles["mobile-items"]}>{stacks}</div>
        </div>
      </div>
    </div>
  );
};

AudioCategoriesCollectionCard.propTypes = {
  accentColor: PropTypes.string,
  collection: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    items: PropTypes.array
  }),
  numberOfCardsInViewDesktop: PropTypes.number,
  numberOfCardsInViewTablet: PropTypes.number,
  numberOfCardsInViewMobile: PropTypes.number,
  menu: PropTypes.array
};

function mapStateToProps(state) {
  return { menu: get(state, ["qt", "data", "header", "AudioHeaderMenu"]) };
}

export default connect(mapStateToProps)(AudioCategoriesCollectionCard);
