import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.m.css";
import SmallStoryCardSeries from "../../atoms/small-story-card-vikatan-series/index";

const VikatanSixColumnGridSeries = ({ stories }) => {
  return (
    <div className={styles.base}>
      <div className={styles["container"]}>
        <div className={styles["label-grid"]}>
          {stories.map(story => (
            <SmallStoryCardSeries
              defaultLabel="மற்றவை"
              key={story.id}
              story={story}
              className={styles["label-story"]}
              setUTM="episode-page"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

VikatanSixColumnGridSeries.propTypes = {
  stories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      contributors: PropTypes.arrayOf(
        PropTypes.shape({
          "role-name": PropTypes.string,
          authors: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string
            })
          )
        })
      ),
      headline: PropTypes.string.isRequired,
      alternative: PropTypes.shape({
        home: PropTypes.shape({
          default: PropTypes.shape({
            headline: PropTypes.string
          })
        })
      }),
      slug: PropTypes.string.isRequired,
      "hero-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
      }),
      "hero-image-s3-key": PropTypes.string,
      "hero-image-caption": PropTypes.string
    })
  )
};

export default VikatanSixColumnGridSeries;
