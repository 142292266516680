import React from "react";
import PropTypes from "prop-types";

import MagazineSliderMain from "../../atoms/magazine-slider-main";

import styles from "./styles.m.css";
import MagazineStaticCard from "../../atoms/magazine-static-card";

export const MagazineStaticOneStory = ({ collection, prevIssue, nextIssue, config, magazine }) => {
  const stories = collection.items.filter(({ story = {} }) => story.headline && story.url).map(item => item.story);
  return (
    <div className={styles.base}>
      <div className="container">
        <h3 className={`${styles["cover-story-title"]} hidden-mobile`}>கவர் ஸ்டோரி</h3>
        <div className={`${styles["grid"]}`}>
          <div className={styles["slider-stories-wrap"]}>
            <h3 className={`${styles["cover-story-title"]} hidden-desktop`}>கவர் ஸ்டோரி</h3>
            <MagazineSliderMain stories={stories.slice(0, 1)} />
          </div>

          <MagazineStaticCard
            className={styles.issue}
            collection={collection}
            prevIssue={prevIssue}
            nextIssue={nextIssue}
            config={config}
            magazine={magazine}
          />
        </div>
      </div>
    </div>
  );
};

MagazineStaticOneStory.propTypes = {
  collection: PropTypes.any,
  prevIssue: PropTypes.any,
  nextIssue: PropTypes.any,
  magazine: PropTypes.any,
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  })
};
MagazineStaticOneStory.storyLimit = 8;

export default MagazineStaticOneStory;
