import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import CollectionLink from "../../utils/generate-collection-link";
import { StoryCardML4x3DP1x1 } from "../../atoms/story-card-ml4x3-dl1x1";
import BundleBigStoryCard from "../../atoms/bundle-big-story-card";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import styles from "./styles.m.css";
import { generateImageSources, getStoryHeadline } from "../../utils/utils";

export const FiveStoriesOneAdBundle = ({ collection, accentColor, pagetype = "" }) => {
  const numberOfStories = get(collection, ["total-count"], 0);
  const stories = collection.items
    .filter(({ story = {} }) => getStoryHeadline(story) && story.url)
    .slice(0, 10)
    .map(({ story }) => story);
  return (
    <div className={styles.base} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container row ${styles.row}`}>
        <div className={`row ${styles["bundle-header"]}`}>
          <CollectionLink collection={collection} className={styles["bundle-header"]}>
            <CollectionTitleWithCrossLine title={collection.name} className={styles.title} />
          </CollectionLink>
          <div className={styles["bundle-summary-wrap"]}>
            {numberOfStories && <span className={styles["total-count"]}> {numberOfStories + " அத்தியாயங்கள்"}</span>}
            <p className={styles["bundle-summary"]}>{collection.summary}</p>
          </div>
        </div>
        <div className={styles["col-3"]}>
          {stories.splice(0, 1).map(story => (
            <BundleBigStoryCard key={story.id} story={story} className={styles["story-2"]} />
          ))}
        </div>
        <div className={styles["col-3"]}>
          {stories.splice(0, 3).map(story => (
            <StoryCardML4x3DP1x1 key={story.id} story={story} className={styles["story-3"]} />
          ))}
        </div>
      </div>
    </div>
  );
};

const CollectionMeta = ({ collection, className }) => {
  const collectionCoverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const collectionCoverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const collectionCoverImageCaption = get(collection, ["metadata", "cover-image", "caption"], collection.name);

  const snapshot = get(collection, ["metadata", "snapshot", "body"], "")
    .split("</p>")
    .map(str => str.replace("<p>", ""))
    .filter(str => str.trim().length);

  const firstTitle = get(snapshot, [0], "");

  return (
    <div className={`${className}`}>
      <ResponsiveImageWithFallback
        className={styles["cover-image-wrapper"]}
        slug={collectionCoverImageS3Key}
        metadata={collectionCoverImageMetaData}
        alt={collectionCoverImageCaption}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          {
            aspectRatio: [3, 1],
            screenWidthCoverage: 1
          },
          {
            aspectRatio: [9, 5],
            screenWidthCoverage: 0.4
          }
        )}
      />
      <div className={styles["meta-text-wrapper"]}>
        <h3 className={styles["meta-headline"]}>{firstTitle.replace(/(<([^>]+)>)/gi, "")}</h3>
        <ul>
          {snapshot &&
            snapshot.slice(1).map((text, index) => {
              const textSnap = text.replace(/(<([^>]+)>)/gi, "");
              if (textSnap)
                return (
                  <li key={index} className={styles["meta-list-item"]}>
                    {textSnap}
                  </li>
                );
            })}
        </ul>
      </div>
    </div>
  );
};

CollectionMeta.propTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string,
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-s3-key": PropTypes.string,
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number,
          "mime-type": PropTypes.string,
          "focus-point": PropTypes.arrayOf(PropTypes.number)
        }),
        caption: PropTypes.string
      })
    })
  }),
  className: PropTypes.string
};

FiveStoriesOneAdBundle.propTypes = {
  collection: CollectionMeta.propTypes.collection,
  metadata: PropTypes.shape({
    read_more_text: PropTypes.string
  }),
  accentColor: PropTypes.string,
  pagetype: PropTypes.string
};
FiveStoriesOneAdBundle.storyLimit = 5;

export default FiveStoriesOneAdBundle;
