import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { LoadMoreStoriesBase } from "@quintype/components";
import { WebEngage, GA, DeepBI, PGAD } from "../../../integrations";
import { LoadMoreButton } from "../../atoms/read-more-link";
import StoryCardML4x3DP1x1 from "../../atoms/story-card-ml4x3-dl1x1";
import { SpecialTopicPage } from "../special-topic-page";
import { FIELDS_FOR_LISTING_PAGE, PAGE_TYPE } from "../../../constants";
import styles from "./styles.m.css";
import { VikatanCollectionResponsive, VikatanSticky, VikatanResponsiveContainer } from "../../rows/ad-free";
import Media from "react-media";
const StoryList = ({ stories }) =>
  stories.length > 0 ? (
    <React.Fragment>
      {stories.map((story, idx) => {
        return (
          <div key={idx} className={styles["story-card"]}>
            <StoryCardML4x3DP1x1 story={story} key={story.id} />
            {idx !== 0 &&
              idx % 4 === 0 && (
                <Media query="(max-width:767px)">
                  <VikatanResponsiveContainer placement={"tag-story"} adtype={parseInt(idx)} className="ad_300_250" />
                </Media>
              )}
          </div>
        );
      })}
    </React.Fragment>
  ) : (
    <h3 className={styles["no-results"]}>No results found</h3>
  );

StoryList.propTypes = {
  stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story)
};

const StoryListBase = ({ stories, loading, onLoadMore, noMoreStories }) => (
  <React.Fragment>
    <StoryList stories={stories} />
    {!noMoreStories &&
      stories.length >= 12 && (
        <div className={styles["load-more-wrap"]}>
          <LoadMoreButton text={loading ? "LOADING..." : " மேலும் படிக்க"} disabled={loading} onClick={onLoadMore} />
        </div>
      )}
  </React.Fragment>
);

export { StoryListBase };

StoryListBase.propTypes = {
  stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story),
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  noMoreStories: PropTypes.bool
};

const TagPage = ({ data }) => {
  if (data.entity) {
    return <SpecialTopicPage data={data} />;
  }
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.TAG_PAGE, {});
    DeepBI.registerPageView({ collection: { ...data.tag, pageType: PAGE_TYPE.TAG_PAGE } });
    PGAD.loadScript();
  }, []);

  const tagName = get(data, ["tag", "title"], null);
  const tagSlug = get(data, ["tag", "slug"], null);

  return (
    <div className={`main-page ${styles["base-container"]}`}>
      <div />
      <div className={styles.base}>
        <div className={`container`}>
          {tagName && <h1 className={styles.headline}>{tagName}</h1>}
          <div className={`row`}>
            <LoadMoreStoriesBase
              template={StoryListBase}
              numStoriesToLoad={9}
              data={data}
              params={{ "tag-slugs": tagSlug }}
              fields={FIELDS_FOR_LISTING_PAGE}
            />
          </div>
        </div>
        <div className={styles["tag-ad"]}>
          <VikatanCollectionResponsive
            DesktopAdtype="Vikatan2023_Desktop_SP_BTF_728x90"
            MobileAdtype="Vikatan2023_Mobile_SP_TOP_320x100"
            placement="bundle"
          />
        </div>
        <div className={styles["magazine-ad"]}>
          <VikatanSticky placement="bundle" />
        </div>
      </div>
      <div />
    </div>
  );
};

export { TagPage };

TagPage.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    }),
    stories: PropTypes.arrayOf(StoryCardML4x3DP1x1.propTypes.story)
  })
};
