import React from "react";
import PropTypes from "prop-types";
import { get, chunk } from "lodash";

import { addAdsToNewTemplates } from "../get-collection-template";
import { LazyCollection, wrapCollectionLayout, LoadMoreCollectionStories } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import { DfpAd } from "../atoms/dfp-ad";
import Carousel from "../atoms/carousel";
import { LoadMoreButton } from "../atoms/read-more-link";
import SliderVideoCard from "../rows/slider-video-card-grid";
import styles from "./webstories.m.css";
const LOAD_MORE_TEXT = "மேலும் வீடியோஸ்";

const newTemplates = addAdsToNewTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates["slider-video-card"];
}

const wrapForLoadMore = (Component, numberOfStoriesItCanAccomodate) => {
  const WrapperForLoadMore = ({ stories, collection, onLoadMore, noMoreStories, loading }) => {
    return (
      <React.Fragment>
        {chunk(stories.slice(12), numberOfStoriesItCanAccomodate).map((stories, index) => {
          const items = stories.map(story => {
            return { id: story.id, type: "story", story };
          });
          return <Component key={index} collection={{ ...collection, items }} pagetype="home" title={false} />;
        })}
        {!noMoreStories &&
          stories.length > numberOfStoriesItCanAccomodate && (
            <div className={styles["load-more-button-wrapper"]}>
              <LoadMoreButton onClick={onLoadMore} disabled={loading} text={LOAD_MORE_TEXT} />
            </div>
          )}
        <DfpAd className="hidden-mobile" adtype="Vikatan_Desktop_SP_BTF1_728x90" placement="section" />
      </React.Fragment>
    );
  };
  WrapperForLoadMore.propTypes = {
    stories: PropTypes.array,
    collection: PropTypes.object,
    onLoadMore: PropTypes.func,
    noMoreStories: PropTypes.func,
    loading: PropTypes.bool
  };
  return WrapperForLoadMore;
};

export class VideoPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  handleFilter = (slug, index) => {
    this.setState({ filter: slug, currentIndex: index, action: false });
    setTimeout(() => this.setState({ action: true }), 500);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { filter, action } = this.state;

    const updateObj = Object.assign({}, this.props.data.collection, {
      items: filter
        ? this.props.data.collection.items.filter((element, index) => element.slug === this.state.filter)
        : this.props.data.collection.items
    });
    const CollectionSlides = this.props.data.collection.items.map((item, index) => (
      <div
        className={`${filter === item.slug && styles["active"]}`}
        onClick={() => this.handleFilter(item.slug, index)}
        key={item.id}
      >
        {item.name}
      </div>
    ));

    CollectionSlides.unshift(
      <div
        onClick={() => {
          this.setState({ filter: null });
        }}
        className={`${filter === null && styles["active"]}`}
      >
        All
      </div>
    );

    const initialStories = get(this.props.data, ["collection", "items", [this.state.currentIndex], "items"], []).filter(
      items => items.type === "story"
    );
    const WrappedSliderVideoCard = wrapCollectionLayout(SliderVideoCard);
    const SelectedCollection = get(this.props.data, ["collection", "items", [this.state.currentIndex]], []);

    return (
      <React.Fragment>
        <div className={`main-page styles-m__base__2vtxy js-story-main-container ${styles["base-class"]}`}>
          <div />
          <div className="page-content infinity-page">
            <div className={`container ${styles["story-grid"]}`}>
              <div className={styles["main-content"]}>
                <h2 className={styles["title"]}>விகடன் டிவி</h2>
                <Carousel
                  className={`${styles["first-slider"]}`}
                  options={{
                    type: "slider",
                    focusAt: 0,
                    bound: true,
                    perView: 7,
                    peek: {
                      before: 0,
                      after: 120
                    },
                    breakpoints: {
                      320: {
                        bound: true,
                        perView: 2,
                        peek: {
                          before: 0,
                          after: 120
                        }
                      },
                      767: {
                        bound: true,
                        perView: 2,
                        peek: {
                          before: 0,
                          after: 120
                        }
                      },
                      991: {
                        bound: true,
                        perView: 3,
                        peek: {
                          before: 0,
                          after: 120
                        }
                      }
                    }
                  }}
                  renderCenterLeftControls={({ previousSlide }) => {
                    return null;
                  }}
                  renderCenterRightControls={({ nextSlide }) => {
                    return null;
                  }}
                  renderBottomCenterControls={({ currentSlide, goToSlide }) => {
                    return null;
                  }}
                  childClass={styles["glide__slide__li"]}
                >
                  {CollectionSlides}
                </Carousel>
                {!filter && (
                  <LazyCollection
                    collection={updateObj}
                    magazine={this.props.data.magazine}
                    config={this.props.config}
                    collectionTemplates={getAdsWrappedCollectionTemplate}
                    lazyAfter={10}
                    accentColor={"#404040"}
                    numberOfCardsInViewDesktop={2}
                    handleAction={(args, index) => this.handleFilter(args, index)}
                  />
                )}

                {action &&
                  filter && (
                    <div className="bg-diamond-dark">
                      <WrappedSliderVideoCard
                        showReadmore={false}
                        collection={{ ...SelectedCollection, items: initialStories.slice(0, 12) }}
                        pagetype="home"
                      />
                      <LoadMoreCollectionStories
                        template={wrapForLoadMore(SliderVideoCard, 12)}
                        collectionSlug={SelectedCollection.slug}
                        data={Object.assign(
                          {},
                          { collection: SelectedCollection },
                          { stories: [...initialStories.map(({ story }) => story).slice(0, 24)] },
                          { storiesPerPage: 12 }
                        )}
                        numStoriesToLoad={12}
                        params={{ "item-type": "story" }}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div />
        </div>
      </React.Fragment>
    );
  }
}

VideoPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
