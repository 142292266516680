import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LoadMoreCollectionStories } from "@quintype/components";
import { WebEngage, GA, DeepBI, PGAD } from "../../../integrations";
import { LoadMoreButton } from "../../atoms/read-more-link";
import SmallStoryCard from "../../atoms/story-card-aspect-ratio-one";
import SmallStoryCardDesktopBig from "../../atoms/small-story-desktop-big";
import StoryCardDP2x1ML1x1 from "../../atoms/story-card-dp-2x1-ml-1x1";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";
import { get, chunk } from "lodash";
import styles from "./styles.m.css";
import { DfpAd } from "../../atoms/dfp-ad";
import { FIELDS_FOR_LISTING_PAGE, PAGE_TYPE } from "../../../constants";
import { fetchAuthorCount } from "../../../api";
import Icon from "../../atoms/icon";

let initialLoad = true;
function computeLoadMoreLimit(storyCountFirstComponent) {
  if (initialLoad) {
    return storyCountFirstComponent;
  } else {
    return 10;
  }
}

const StoryList = ({ stories }) => {
  return stories.length > 0 ? (
    <div className={styles["story-card-container"]}>
      <div className={styles["bg-story-container"]}>
        {stories.slice(0, 1).map((story, index) => (
          <SmallStoryCardDesktopBig story={story} key={index} className={styles["story-card-no-border"]} />
        ))}
        {stories.slice(1, 2).map((story, index) => (
          <StoryCardDP2x1ML1x1 story={story} key={index} />
        ))}
        {stories.slice(2, 4).map((story, index) => (
          <StoryCardDP2x1ML1x1 story={story} key={index} />
        ))}
      </div>
      <div className={styles["small-card-container"]}>
        {stories.slice(4, 10).map((story, index) => (
          <SmallStoryCard story={story} key={index} className={styles["story-card-bg"]} />
        ))}
      </div>
    </div>
  ) : (
    <h3 className={styles["no-results"]}>No results found</h3>
  );
};
StoryList.propTypes = {
  stories: PropTypes.arrayOf(SmallStoryCard.propTypes.story)
};

const StoryListWithStats = ({ stories, count }) => {
  return stories.length > 0 ? (
    <div className={styles["story-card-container"]}>
      <div className={styles["bg-story-container"]}>
        {stories.slice(0, 1).map((story, index) => (
          <SmallStoryCardDesktopBig story={story} key={index} className={styles["story-card-no-border"]} />
        ))}
        {stories.slice(1, 2).map((story, index) => (
          <StoryCardDP2x1ML1x1 story={story} key={index} />
        ))}
        {stories.slice(2, 4).map((story, index) => (
          <StoryCardDP2x1ML1x1 story={story} key={index} />
        ))}
      </div>
      <div className={styles["sidebar-story-container"]}>
        <div className={styles["small-card-container"]}>
          {stories.slice(5, count).map((story, index) => (
            <SmallStoryCard story={story} key={index} className={styles["story-card-bg"]} />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <h3 className={styles["no-results"]}>No results found</h3>
  );
};

StoryListWithStats.propTypes = {
  stories: PropTypes.arrayOf(SmallStoryCard.propTypes.story),
  count: PropTypes.number,
  statistics: PropTypes.object
};

const AuthorBio = ({ author }) => {
  const bio = get(author, ["bio"], "");
  const name = get(author, ["name"], "");
  const [authorAggregation, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const authorId = get(author, ["id"]);
  const statistics = get(author, ["stats", "contributions"]) || {};
  useEffect(() => {
    let isSubscribed = true;
    fetchAuthorCount(authorId).then(authorResult => {
      if (isSubscribed) {
        const dataAuthorCount = authorResult;
        const total = get(dataAuthorCount, ["total"]);
        setTotal(total);
        let finalAuthorResult = [];
        let totalContributions = 0;
        for (let i in statistics) {
          totalContributions += statistics[i];
        }

        let finalResult = Object.assign(dataAuthorCount["aggregations"]["story-attributes.articletype"], statistics, {
          "Total Contributions": totalContributions
        });

        for (let [key, value] of Object.entries(finalResult)) {
          let firstName, lastName, iconType, isChecked;
          switch (key) {
            case "Total Contributions": {
              firstName = "Total";
              lastName = "Contributions";
              iconType = "authoranchor";
              break;
            }
            case "photographer": {
              firstName = "Photographed";
              lastName = "Articles";
              iconType = "photographer";
              break;
            }
            case "Script Writer": {
              firstName = "Scripted";
              lastName = "Videos";
              iconType = "scripted";
              break;
            }
            case "Video editor": {
              firstName = "Edited";
              lastName = "Videos";
              iconType = "video-editor";
              break;
            }
            case "Producer": {
              firstName = "Produced";
              lastName = "Videos";
              iconType = "cinema";
              break;
            }
            case "MoJo": {
              firstName = "Shot Mobile";
              lastName = "Videos";
              iconType = "videographer";
              break;
            }
            case "videographer": {
              firstName = "Filmed";
              lastName = "Videos";
              iconType = "cinema";
              break;
            }
            case "Data Journalist": {
              firstName = "Data for";
              lastName = "Articles";
              iconType = "data";
              break;
            }
            case "Infographer": {
              firstName = "Designed";
              lastName = "Infographs";
              iconType = "designed";
              break;
            }
            case "Published By": {
              firstName = "Published";
              lastName = "Articles";
              iconType = "published";
              break;
            }
            case "Moderated by": {
              firstName = "Moderated";
              lastName = "Articles";
              iconType = "moderated";
              break;
            }
            case "Narrator": {
              firstName = "Narrated";
              lastName = "Audios";
              iconType = "narrated";
              break;
            }
            case "Sound Designer": {
              firstName = "Sound Designed";
              lastName = "Audios";
              iconType = "sound";
              break;
            }
            case "Illustrator": {
              firstName = "Illustrations";
              lastName = "";
              iconType = "illustrator";
              break;
            }
            case "Proof Reader": {
              firstName = "Proofread";
              lastName = "Articles";
              iconType = "proofread";
              break;
            }
            case "ஷார்ட் நியூஸ்": {
              firstName = "Written";
              lastName = "Articles";
              iconType = "writtenarticles";
              break;
            }
            case "டெப்த் நியூஸ்": {
              firstName = "Written";
              lastName = "Stories ";
              iconType = "writtenstory";
              break;
            }
            case "சிறுகதை": {
              firstName = "Short Stories";
              lastName = "";
              iconType = "shortstories";
              break;
            }
            case "கவிதை": {
              firstName = "Poems";
              lastName = "";
              iconType = "poems";
              break;
            }
            case "பேட்டி": {
              firstName = "Interviews";
              lastName = "";
              iconType = "interviews";
              break;
            }
            case "அலசல்": {
              firstName = "Analysis";
              lastName = "";
              iconType = "analysis";
              break;
            }
            case "ஜோக்": {
              firstName = "Jokes";
              lastName = "";
              iconType = "jokes";
              break;
            }
            default: {
              firstName = key;
              isChecked = false;
              break;
            }
          }
          if (isChecked !== false) {
            finalAuthorResult.push({ id: key, name: firstName, count: value, last: lastName, type: iconType });
          }
        }
        let result = finalAuthorResult.sort((a, b) => parseFloat(b.count) - parseFloat(a.count));
        setData(result.slice(0, 7));
      }
    });
    return () => (isSubscribed = false);
  }, []);

  const totalCount = authorAggregation.reduce((sum, item) => sum + item.count, 0);
  return (
    <div className={styles["author-bio"]}>
      <DfpAd adtype="mobile-leaderboard" placement="author" />
      <div className={styles["author-avatar"]}>
        <ResponsiveImageWithFallback
          className={styles["author-avatar-img"]}
          slug={author["avatar-s3-key"]}
          metadata={author["metadata"]}
          alt={author.name}
          imgParams={{ auto: ["format", "compress"] }}
          sources={generateImageSources(
            { aspectRatio: [16, 9], screenWidthCoverage: 0.4 },
            { aspectRatio: [16, 9], screenWidthCoverage: 0.42 }
          )}
        />
      </div>
      <div className={styles["author-details"]}>
        <div className={styles["author-name-follow-container"]}>
          <h1 className={styles["author-name"]}>{name}</h1>
          {author["twitter-handle"] && (
            <a
              href={`http://twitter.com/${author["twitter-handle"]}`}
              className={styles["twitter-share"]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow
            </a>
          )}
        </div>
        <div className={`${styles["author-about"]} show-tablet-and-desktop`}>
          {bio}
          <div className={styles["author-count"]}>
            <div className={styles["counter-div"]}>
              <div className={styles["counter-icon"]}>
                <Icon type={"authoranchor"} className={styles["icon-style"]} />
              </div>
              <div className={styles["counter-collection"]}>
                <div className={styles["counter-name"]}>&nbsp; Total</div>
                <div className={styles["counter-value"]}>
                  &nbsp;
                  {total - totalCount}
                </div>
                <div className={styles["counter-name"]}>&nbsp; Stories</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles["author-about"]} hidden-desktop`}>
        {bio}
        <div className={styles["author-count"]}>
          {authorAggregation.map(station => (
            <div className={styles["counter-div"]} key={station.id}>
              <div className={styles["counter-icon"]}>
                <Icon type={station.type} className={styles["icon-style"]} />
              </div>
              <div className={styles["counter-collection"]}>
                <div className={styles["counter-name"]}>
                  &nbsp;
                  {station.name}
                </div>
                <div className={styles["counter-value"]}>
                  &nbsp;
                  {station.count}
                </div>
                <div className={styles["counter-name"]}>
                  &nbsp;
                  {station.last}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AuthorBio.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    bio: PropTypes.string,
    "twitter-handle": PropTypes.string,
    "avatar-s3-key": PropTypes.string
  })
};

const wrapForLoadMore = (Component, numberOfStoriesItCanAccomodate, statistics, numberofStoriesFirstComponent) => {
  const WrapperForLoadMore = ({ stories, onLoadMore, noMoreStories, loading }) => {
    if (stories.length === 0) {
      return <h3 className={styles["no-results"]}>No stories found.</h3>;
    }
    return (
      <React.Fragment>
        {stories.length <= numberofStoriesFirstComponent ? (
          <StoryListWithStats stories={stories} count={numberofStoriesFirstComponent} statistics={statistics} />
        ) : (
          [
            <StoryListWithStats
              key={"sl-stats"}
              stories={stories.slice(0, numberofStoriesFirstComponent)}
              count={numberofStoriesFirstComponent}
              statistics={statistics}
            />,
            chunk(stories.slice(numberofStoriesFirstComponent, stories.length), numberOfStoriesItCanAccomodate).map(
              (stories, index) => {
                return <Component key={index} stories={stories} />;
              }
            )
          ]
        )}
        {!noMoreStories &&
          !(stories.length < computeLoadMoreLimit(numberofStoriesFirstComponent)) && (
            <div className={styles["load-more-wrap"]}>
              <LoadMoreButton
                onClick={e => {
                  initialLoad = false;
                  onLoadMore(e);
                }}
                disabled={loading}
                text={loading ? "LOADING..." : " மேலும் படிக்க"}
              />
            </div>
          )}
      </React.Fragment>
    );
  };
  WrapperForLoadMore.propTypes = {
    stories: PropTypes.array,
    collection: PropTypes.object,
    onLoadMore: PropTypes.func,
    noMoreStories: PropTypes.func,
    loading: PropTypes.bool
  };
  return WrapperForLoadMore;
};

const AuthorPage = ({ data }) => {
  const authorId = get(data, ["author", "id"]);
  const statistics = get(data, ["author", "stats", "contributions"]) || {};
  const numberofStoriesFirstComponent = data["numberofStoriesFirstComponent"] || 0;

  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.AUTHOR_PAGE, { author: get(data, ["author"], {}) });
    DeepBI.registerPageView({
      collection: { data: { collection: get(data, ["author"], {}) }, pageType: PAGE_TYPE.AUTHOR_PAGE }
    });
    PGAD.loadScript();
  }, []);
  return (
    <div className={`main-page ${styles["main-container"]}`}>
      <div />
      <div className={styles.base}>
        <div className={`container`}>
          <div className={`row`}>
            <AuthorBio author={data.author} />
            <span className={styles["header"]}>My Best Picks</span>
            <LoadMoreCollectionStories
              template={wrapForLoadMore(StoryList, 10, statistics, numberofStoriesFirstComponent)}
              data={data}
              authorId={authorId}
              params={{ fields: FIELDS_FOR_LISTING_PAGE }}
              numStoriesToLoad={10}
            />
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export { AuthorPage };

AuthorPage.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string
    }),
    stories: PropTypes.arrayOf(SmallStoryCard.propTypes.story)
  })
};
