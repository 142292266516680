import React from "react";
import PropTypes from "prop-types";
import { get, chunk } from "lodash";
import { LazyCollection, wrapCollectionLayout, LoadMoreCollectionStories } from "@quintype/components";
import { addAdsToTemplates } from "../../get-collection-template";
import FourStoryOneAdOneWidget from "../../rows/four-story-one-ad-one-widget";
import EighteenStoriesOneAd from "../../rows/eighteen-stories-one-ad";
import SecondaryMenu from "../../atoms/secondary-menu";
import { LoadMoreButton } from "../../atoms/read-more-link";
import { GA, WebEngage, DeepBI, EVOLOK } from "../../../integrations";
import styles from "./styles.m.css";
import { VikatanDesktopSticky, VikatanSticky } from "../../rows/ad-free";
import Breadcrumb from "../story/story-templates/shared/breadcrumbs";

const LOAD_MORE_TEXT = "மேலும் படிக்க";

const newTemplates = addAdsToTemplates("section");

function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

const wrapForLoadMore = (Component, numberOfStoriesItCanAccomodate) => {
  const WrapperForLoadMore = ({ stories, collection, onLoadMore, noMoreStories, loading }) => {
    return (
      <React.Fragment>
        {chunk(stories.slice(5), numberOfStoriesItCanAccomodate).map((stories, index) => {
          const items = stories.map(story => {
            return { id: story.id, type: "story", story };
          });
          return <Component key={index} collection={{ ...collection, items }} pagetype="section" />;
        })}
        {!noMoreStories &&
          stories.length > numberOfStoriesItCanAccomodate && (
            <div className={styles["load-more-button-wrapper"]}>
              <LoadMoreButton onClick={onLoadMore} disabled={loading} text={LOAD_MORE_TEXT} />
            </div>
          )}
      </React.Fragment>
    );
  };
  WrapperForLoadMore.propTypes = {
    stories: PropTypes.array,
    collection: PropTypes.object,
    onLoadMore: PropTypes.func,
    noMoreStories: PropTypes.func,
    loading: PropTypes.bool
  };
  return WrapperForLoadMore;
};

function shouldShowCollectionLayout(collection) {
  const firstCollection = get(collection, ["items"], []).find(item => item.type === "collection");
  return !!firstCollection;
}
export class SectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      random: 0
    };
  }

  componentDidMount() {
    const sections = [this.props.data.section];
    if (this.props.data.parentSection) {
      sections.unshift(this.props.data.parentSection);
    }
    GA.registerPageView(this.props.pageType, { section: this.props.data.section });
    WebEngage.registerPageView({ section: this.props.data.section });
    EVOLOK.loadScript(this.props.pageType, { collection: this.props });
    DeepBI.registerPageView({ collection: this.props });
  }

  render() {
    const stories = get(this.props.data, ["collection", "items"], []);

    const sectionSlug = get(this.props.data, ["collection", "slug"], "");
    const section = get(this.props.data, ["section"], {});
    const collectionName = get(this.props.data, ["collection", "name"], "");
    const sectionMenu = get(this.props.data, ["menuData"], null);
    const sectionColor = get(section, ["data", "color"], "#2c2c2c");
    const pageType = get(this.props, ["pageType"], null);

    const alternateImageurl = get(
      this.props.data,
      ["collection", "metadata", "alternative-image-url"],
      "https://gumlet.vikatan.com/vikatan/2023-08/84a189be-643b-4faa-8785-64d6d6940203/dummy_fotor_bg_remover_20230810211024.png?rect=128&auto=format&compress&format=webp&w=480&dpr=1.0"
    );

    const menuStyle = {
      backgroundColor: sectionColor
    };

    const backgroundImageStyle = {
      backgroundImage: `url(${alternateImageurl})`
    };

    if (stories.length < 1) {
      return (
        <div className={styles["default-section-page"]}>
          <div className={styles["no-stories"]}>
            <h2 className={styles["no-stories-status"]}>No stories found.</h2>
          </div>
        </div>
      );
    }
    if (shouldShowCollectionLayout(this.props.data.collection)) {
      const collectionItems = get(this.props.data, ["collection", "items"], []).filter(
        items => items.type === "collection"
      );
      const collection = { ...this.props.data.collection, items: [...collectionItems] };

      const isCricketWorldCupPage = sectionSlug === "cricket-world-cup-2023";

      const secondHeaderSection = isCricketWorldCupPage ? (
        <div className={styles["header-section"]} style={{ background: "#f8f8f8" }}>
          <div className={`${styles["section-page-container-full-width"]}`}>
            <div
              style={{
                backgroundImage:
                  "url('https://images.assettype.com/vikatan/2023-10/cec74e5c-3195-4b02-8fd4-ee26d3a43649/header_main_banner_mobile.jpg')"
              }}
              className={`${styles["bg-image-sec"]} hidden-desktop`}
            >
              {sectionMenu ? (
                <SecondaryMenu
                  menuGroup={sectionMenu}
                  slug={sectionMenu["slug"] || sectionSlug}
                  sectionpageMenu={sectionMenu}
                  className={styles["section-page-menu"]}
                />
              ) : null}
            </div>

            <div style={backgroundImageStyle} className={`${styles["bg-image-sec"]} hidden-mobile`}>
              {sectionMenu ? (
                <SecondaryMenu
                  menuGroup={sectionMenu}
                  slug={sectionMenu["slug"] || sectionSlug}
                  sectionpageMenu={sectionMenu}
                  className={styles["section-page-menu"]}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles["header-section"]} style={menuStyle}>
          <div className={`${styles["section-page-container"]} container`}>
            <div style={backgroundImageStyle} className={styles["bg-image-sec"]}>
              {this.props.data.section ? (
                <Breadcrumb
                  config={this.props.config}
                  subSection={this.props.data.section}
                  className="section-Breadcrumb"
                />
              ) : (
                <Breadcrumb
                  config={this.props.config}
                  collection={this.props.data.collection}
                  className="section-Breadcrumb"
                />
              )}
              {collectionName ? <h1 className={styles["headline"]}>{collectionName}</h1> : null}
              {sectionMenu ? (
                <SecondaryMenu
                  menuGroup={sectionMenu}
                  slug={sectionMenu["slug"] || sectionSlug}
                  sectionpageMenu={sectionMenu}
                  className={styles["section-page-menu"]}
                />
              ) : null}
            </div>
          </div>
        </div>
      );

      return (
        <div className={`${styles["default-section-page"]} ${styles["default-section-page-container"]}`}>
          {secondHeaderSection}

          <div className={styles["section-page"]}>
            <div
              className={
                sectionSlug === "cricket-world-cup-2023" ? styles["cricket-collection"] : styles["first-collection"]
              }
            >
              <LazyCollection
                collection={collection}
                collectionTemplates={getAdsWrappedCollectionTemplate}
                lazyAfter={4}
                accentColor={sectionColor}
                pageType={pageType}
              />
            </div>
          </div>
          <VikatanSticky placement="bundle" />
        </div>
      );
    }
    const initialStories = get(this.props.data, ["collection", "items"], []).filter(items => items.type === "story");
    const WrappedFourStoryOneAdOneWidget = wrapCollectionLayout(FourStoryOneAdOneWidget);

    return (
      <div className={`${styles["default-section-page"]} ${styles["default-section-page-container"]}`}>
        <div className={styles["header-section"]} style={menuStyle}>
          <div className={`${styles["section-page-container"]} container`}>
            <div style={backgroundImageStyle} className={styles["bg-image-sec"]}>
              {this.props.data.section ? (
                <Breadcrumb
                  config={this.props.config}
                  subSection={this.props.data.section}
                  className="section-Breadcrumb"
                />
              ) : (
                <Breadcrumb
                  config={this.props.config}
                  collection={this.props.data.collection}
                  className="section-Breadcrumb"
                />
              )}
              {collectionName ? <h1 className={styles["headline"]}>{collectionName}</h1> : null}
              {sectionMenu ? (
                <SecondaryMenu
                  communityColor={sectionMenu["menuBgColor"] || sectionColor}
                  menuGroup={sectionMenu}
                  slug={sectionMenu["slug"] || sectionSlug}
                  sectionpageMenu={sectionMenu}
                  className={styles["section-page-menu"]}
                />
              ) : null}
            </div>
          </div>
        </div>

        <div className={styles["section-page"]}>
          <div
            className={
              sectionSlug === "cricket-world-cup-2023" ? styles["cricket-collection"] : styles["first-collection"]
            }
          >
            <WrappedFourStoryOneAdOneWidget
              showReadmore={false}
              accentColor={sectionColor}
              collection={{ ...this.props.data.collection, items: initialStories.slice(0, 7) }}
              pagetype="section"
            />
          </div>

          <LoadMoreCollectionStories
            template={wrapForLoadMore(EighteenStoriesOneAd, 19)}
            collectionSlug={this.props.data.collection.slug}
            data={Object.assign(
              {},
              { collection: this.props.data.collection },
              { stories: [...initialStories.map(({ story }) => story).slice(0, 24)] },
              { storiesPerPage: 19 }
            )}
            numStoriesToLoad={19}
            params={{ "item-type": "story" }}
          />
        </div>

        <div />
        <VikatanSticky placement="bundle" />
        <VikatanDesktopSticky placement="bundle" />
      </div>
    );
  }
}

SectionPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    section: PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.shape({
        color: PropTypes.string
      })
    }),
    parentSection: PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.shape({
        color: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({}),
  pageType: PropTypes.string
};
