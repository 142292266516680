import React from "react";
import PropTypes from "prop-types";
import VikatanSeriesCardBase from "../../atoms/vikatan-series-collection-card/index";
import styles from "./styles.m.css";
export const VikatanSeriesCard = ({ collection, prevIssue, nextIssue, config }) => {
  return (
    <div className={styles.base}>
      <div>
        <VikatanSeriesCardBase
          className={styles.issue}
          collection={collection}
          prevIssue={prevIssue}
          nextIssue={nextIssue}
          config={config}
        />
      </div>
    </div>
  );
};

VikatanSeriesCard.propTypes = {
  collection: PropTypes.any,
  prevIssue: PropTypes.any,
  nextIssue: PropTypes.any,
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  })
};
VikatanSeriesCard.storyLimit = 8;

export default VikatanSeriesCard;
