import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import { format } from "date-fns";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";
import styles from "./styles.m.css";

import { GA } from "../../../integrations";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const MagazineStaticCardBase = ({
  collection,
  magazine,
  className = "",
  cardWithImageZoom = true,
  subscriptionHost = "",
  config
}) => {
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");
  const issueDate = get(collection, ["collection-date"]);

  const linkName = "Download Now";
  const downloadLink = `https://image.vikatan.com/nanayam-vikatan/supplement/NV-29092024.pdf`;

  const handleEvent = () => {
    const id = cookies.get("vuid") || cookies.get("thinmint");
    GA.customEvent("magazineDownload", format(new Date(issueDate), "dd-MM-yyyy"), id);
  };

  return (
    <div className={`${styles["base"]} ${className} ${cardWithImageZoom ? "card-with-image-zoom" : ""}`}>
      <div className={styles["image-container"]}>
        <a href={downloadLink}>
          <ResponsiveImageWithFallback
            className={`${styles["image-wrapper"]} qt-figure`}
            slug={coverImageS3Key}
            metadata={coverImageMetaData}
            alt={imgAltText}
            imgParams={{ auto: ["format", "compress"] }}
            sources={generateImageSources(
              { aspectRatio: [3, 4], screenWidthCoverage: 0.95 },
              { aspectRatio: [3, 4], screenWidthCoverage: 0.32 }
            )}
          />
        </a>
      </div>
      <br />
      <a
        onClick={() => handleEvent()}
        href={downloadLink}
        className={styles["buy-btn"]}
        rel="noopener noreferrer"
        target="_blank"
      >
        {linkName}
      </a>
    </div>
  );
};

MagazineStaticCardBase.propTypes = {
  className: PropTypes.string,
  subscriptionHost: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  collection: PropTypes.shape({
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        }),
        "cover-image-s3-key": PropTypes.string
      }),
      magazine: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  prevIssue: PropTypes.object,
  nextIssue: PropTypes.object,
  magazine: PropTypes.number
};

export const MagazineStaticCard = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(MagazineStaticCardBase);

export default MagazineStaticCard;
