import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { fetchWCScoreTable } from "../../../api";

import styles from "./styles.m.css";

const WCScoreTable = ({ slug }) => {
  const [matchList, setData] = useState([]);

  useEffect(() => {
    fetchWCScoreTable(slug).then(data => {
      setData(data);
    });
  }, []);
  const teams = get(matchList, ["data", "teams"], {});
  const innings = get(matchList, ["data", "play", "innings"], {});
  const inningsOrder = get(matchList, ["data", "play", "innings_order"], []);
  const shortName = get(matchList, ["data", "short_name"], "");

  const teamAName = get(teams, ["a", "code"], "");
  const teamBName = get(teams, ["b", "code"], "");

  const teamsAkey = get(teams, ["a", "key"], " ");
  const teamsBkey = get(teams, ["b", "key"], " ");

  const teamAScore = get(innings, ["a_1", "score_str"], "");
  const teamBScore = get(innings, ["b_1", "score_str"], "");

  const winnersResult = get(matchList, ["data", "play", "result", "msg"], "");

  const inningsTable = match => {
    const checkteamArray = ["a_1", "b_1"];
    const battingOrder = get(innings, [match, "batting_order"], {});
    const players = get(matchList, ["data", "players"], {});
    const bowlingteam = checkteamArray && checkteamArray.filter(i => i !== match);
    const bowlingOrder = get(innings, [bowlingteam, "bowling_order"], {});
    return <div>{inningstableStructure(battingOrder, players, bowlingOrder)}</div>;
  };

  const [openItem, setOpenItem] = useState(0);

  const toggleAccordion = index => {
    if (index === openItem) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };

  const inningstableStructure = (battingOrder, players, bowlingOrder) => {
    return (
      <div className={`${styles["table-grid"]}`}>
        <div className={`${styles["point-table"]}`}>
          <h1 className={`${styles["point-table-title"]}`}>Batting</h1>
          <table className={styles["inner-table"]}>
            <tr>
              <th>Player Name</th>
              <th />
              <th>
                <span className={`${styles["point-dsk"]}`}>R</span>
                <span className={`${styles["point-mobile"]}`}>R</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>B</span>
                <span className={`${styles["point-mobile"]}`}>B</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>4s</span>
                <span className={`${styles["point-mobile"]}`}>4s</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>6s</span>
                <span className={`${styles["point-mobile"]}`}>6s</span>
              </th>
              <th>s/R</th>
            </tr>
            {battingOrder.length > 0 &&
              battingOrder.map((list, index) => {
                const playerName = get(players, [list, "player"], {});
                const playerScoreDetails = get(players, [list, "score", 1, "batting", "score"], null);
                const playerDismissalMsg = get(players, [list, "score", 1, "batting", "dismissal", "msg"], "Not Out");

                return (
                  <tr key={index}>
                    <td>
                      <table className={styles["inner-table"]}>
                        <tr>
                          <td>{playerName.name}</td>
                        </tr>
                      </table>
                    </td>
                    <td>{playerDismissalMsg}</td>
                    <td>{playerScoreDetails && playerScoreDetails.runs}</td>
                    <td>{playerScoreDetails && playerScoreDetails.balls}</td>
                    <td>{playerScoreDetails && playerScoreDetails.fours}</td>
                    <td>{playerScoreDetails && playerScoreDetails.sixes}</td>
                    <td>{playerScoreDetails && playerScoreDetails.strike_rate}</td>
                  </tr>
                );
              })}
          </table>
        </div>
        <div className={`${styles["point-table"]}`}>
          <h1 className={`${styles["point-table-title"]}`}>Bowling</h1>
          <table className={styles["inner-table"]}>
            <tr>
              <th>Player Name</th>
              <th />
              <th>
                <span className={`${styles["point-dsk"]}`}>O</span>
                <span className={`${styles["point-mobile"]}`}>O</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>M</span>
                <span className={`${styles["point-mobile"]}`}>M</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>R</span>
                <span className={`${styles["point-mobile"]}`}>R</span>
              </th>
              <th>
                <span className={`${styles["point-dsk"]}`}>W</span>
                <span className={`${styles["point-mobile"]}`}>W</span>
              </th>
              <th>Extras</th>
              <th>Eco</th>
            </tr>
            {bowlingOrder.length > 0 &&
              bowlingOrder.map((list, index) => {
                const playerName = get(players, [list, "player"], {});
                const playerScoreDetails = get(players, [list, "score", 1, "bowling", "score"], null);
                return (
                  <tr key={index}>
                    <td>
                      <table className={styles["inner-table"]}>
                        <tr>
                          <td>{playerName.name}</td>
                        </tr>
                      </table>
                    </td>
                    <td />
                    <td>{playerScoreDetails && playerScoreDetails.overs[0] + "." + playerScoreDetails.overs[1]}</td>
                    <td>{playerScoreDetails && playerScoreDetails.maiden_overs}</td>
                    <td>{playerScoreDetails && playerScoreDetails.runs}</td>
                    <td>{playerScoreDetails && playerScoreDetails.wickets}</td>
                    <td>{playerScoreDetails && playerScoreDetails.extras}</td>
                    <td>{playerScoreDetails && playerScoreDetails.economy}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={styles["base"]}>
      <div className={`${styles["score-card-header"]} container`}>
        <div className={`${styles["card-header"]}`}>{shortName}</div>
        <div className={`${styles["team-header"]}`}>
          <div className={`${styles["team-sec"]}`}>
            <div className={`${styles["img-sec"]} ${styles[teamsAkey]}`} />
            <div className={`${styles["main-score"]}`}>
              <h6>{teamAName}</h6>
              <span>{teamAScore}</span>
            </div>
          </div>
          <div className={`${styles["vs"]}`}>VS</div>
          <div className={`${styles["team-sec"]} ${styles["team-sec-groupb"]}`}>
            <div className={`${styles["main-score"]}`}>
              <h6>{teamBName}</h6>
              <span>{teamBScore}</span>
            </div>
            <div className={`${styles["img-sec"]} ${styles[teamsBkey]}`} />
          </div>
        </div>
        <div className={`${styles["winner-result"]}`}>{winnersResult}</div>
        {inningsOrder &&
          inningsOrder.map((item, i) => {
            const getTeamStat = item.replace(/_1/g, "");
            const teamName = get(teams, [getTeamStat, "name"], "");
            const teamScore = get(innings, [item, "score_str"], "");
            return (
              <div key={i} className={`${styles["team-wrapper"]}`}>
                <div className={`${styles["team-head-section"]}`} onClick={() => toggleAccordion(i)}>
                  <h6>{teamName}</h6>
                  <span>{teamScore}</span>
                </div>
                <div className={`${styles["team-content"]}`}>{inningsTable(item)}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
WCScoreTable.propTypes = {
  slug: PropTypes.string
};

export default WCScoreTable;
