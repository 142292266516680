import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import styles from "./styles.m.css";

export const PlayerTableList = ({ item, className = "", teamGroup }) => {
  const allPlayersList = get(item, ["players"], []);
  const team = get(item, ["teams", teamGroup, "match", "playing_xi"], []);
  const fallOfWickets = get(item, ["innings", `${teamGroup}_1`, "fall_of_wickets"], []);
  const teamName = get(item, ["teams", teamGroup, "key"], " ");
  const innings = get(item, ["innings", `${teamGroup}_1`, "run_str"], " ");

  return (
    <div className={`${styles["base-sec"]}`}>
      {team && (
        <div className={`${styles["innings-title"]}`}>
          {teamName}
          <span>{innings}</span>
        </div>
      )}
      {team && (
        <div className={`${styles["main-table-scroll"]}`}>
          <table className={`${styles["main"]}`}>
            <tr>
              <th>BATSMAN</th>
              <th>Runs</th>
              <th>balls</th>
              <th>4s</th>
              <th>6s</th>
              <th>SR</th>
            </tr>
            {team.length > 0 &&
              team.map((list, index) => {
                const teamAdata = get(allPlayersList, [list], "");
                const playersBatting = get(allPlayersList, [list, "match", "innings", 1, "batting"], "");
                return (
                  <tr key={index}>
                    <td>
                      {teamAdata.fullname}
                      <p>{playersBatting && playersBatting.out_str}</p>
                    </td>
                    <td>{playersBatting && playersBatting.runs}</td>
                    <td>{playersBatting && playersBatting.balls}</td>
                    <td>{playersBatting && playersBatting.fours}</td>
                    <td>{playersBatting && playersBatting.sixes}</td>
                    <td>{playersBatting && playersBatting.strike_rate}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      )}
      {team && (
        <div className={`${styles["main-table-scroll"]}`}>
          <table className={`${styles["main"]}`}>
            <tr>
              <th>BOWLERS</th>
              <th>Overs</th>
              <th>Maiden</th>
              <th>Runs</th>
              <th>Wickets</th>
              <th>WD</th>
              <th>NB</th>
              <th>ECON</th>
            </tr>
            {team.length > 0 &&
              team.map((list, index) => {
                const teamAdata = get(allPlayersList, [list], "");
                const playersBowling = get(allPlayersList, [list, "match", "innings", 1, "bowling"], "");
                if (!playersBowling.overs) {
                  return;
                }
                return (
                  <tr key={index}>
                    <td>{teamAdata.fullname}</td>
                    <td>{playersBowling && playersBowling.overs}</td>
                    <td>{playersBowling && playersBowling.maiden_overs}</td>
                    <td>{playersBowling && playersBowling.runs}</td>
                    <td>{playersBowling && playersBowling.wickets}</td>
                    <td>{playersBowling && playersBowling.extras_wide}</td>
                    <td>{playersBowling && playersBowling.extras_noball}</td>
                    <td>{playersBowling && playersBowling.economy}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      )}

      {fallOfWickets.length > 0 && (
        <div className={`${styles["fallofwickets"]}`}>
          <h2>FALL OF WICKETS</h2>
          {fallOfWickets.map(function(item, index) {
            return <span key={`wicket${index}`}>{(index ? ", " : "") + item}</span>;
          })}
        </div>
      )}
    </div>
  );
};

PlayerTableList.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape(),
  team: PropTypes.shape(),
  fallOfWickets: PropTypes.shape(),
  teamGroup: PropTypes.string
};

export default PlayerTableList;
