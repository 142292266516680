import React, { useEffect, useState } from "react";
import { fetchYTVideos } from "../../../api";
import styles from "./styles.m.css";
import Carousel from "../../atoms/carousel";
import Icon from "../../atoms/icon";
import PropTypes from "prop-types";

const YoutubeVideoGallery = ({ title, titleClassName }) => {
  const [videoData, setVideoData] = useState("");

  useEffect(() => {
    fetchYTVideos()
      .then(data => {
        if (data.items) {
          const videos = data.items.map(item => ({
            videoId: item.id.videoId,
            title: item.snippet.title,
            description: item.snippet.description
          }));
          setVideoData(videos);
        }
      })
      .catch(error => {
        console.error("Error fetching video data:", error);
      });
  }, []);

  return (
    <div className={styles["yt-outer"]}>
      <h6 className={`${styles[titleClassName]}`}>{title}</h6>
      <div>
        {videoData && (
          <Carousel
            className={`${styles["first-slider"]}`}
            options={{
              type: "slider",
              focusAt: 0,
              bound: true,
              perView: 3,
              gap: 20,
              peek: {
                before: 0,
                after: 120
              },
              breakpoints: {
                320: {
                  bound: true,
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 120
                  }
                },
                767: {
                  bound: true,
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 120
                  }
                },
                991: {
                  bound: true,
                  perView: 1,
                  peek: {
                    before: 0,
                    after: 120
                  }
                }
              }
            }}
            renderCenterLeftControls={({ previousSlide, currentSlide }) =>
              currentSlide > 0 ? (
                <button
                  aria-label="previous slide"
                  role="button"
                  onClick={previousSlide}
                  className={`${styles["slider-nav-button"]}`}
                >
                  <Icon type="angle-left" className={styles.icon} />
                </button>
              ) : null
            }
            renderCenterRightControls={({ nextSlide }) => (
              <button
                aria-label="next slide"
                role="button"
                onClick={nextSlide}
                className={`${styles["slider-nav-button"]}`}
              >
                <Icon type="angle-right" className={styles.icon} />
              </button>
            )}
            childClass={styles["glide_li"]}
            renderBottomCenterControls={({ currentSlide, goToSlide }) => {
              return null;
            }}
          >
            {videoData.map((video, index) => (
              <div key={index}>
                <iframe
                  src={`https://www.youtube.com/embed/${video.videoId}`}
                  allowFullScreen
                  title={video.title}
                  style={{ borderRadius: "20px" }}
                  className={styles["iframe-olympics-videos"]}
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

YoutubeVideoGallery.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string
};

export default YoutubeVideoGallery;
