import React from "react";
import PropTypes from "prop-types";

import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./audio-magazine.m.css";
import AudioMagazineListCard from "./story/story-templates/shared/audio-magazine-list-card";
import AudioCategoriesCollectionCard from "../rows/audio-categories-collection-slider";
const newTemplates = addWithoutAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate, index) {
  if (index >= 3) {
    return newTemplates["audio-collection-3"];
  }
  return newTemplates[`audio-collection-${index}`];
}

export class AudioMagazinePage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  render() {
    //  const { collection, data } = this.props;

    // if (!collection) {
    //   console.error("Collection is not passed to the component.");
    //   return null;
    // }

    // console.log("Collection M -->", this.props.data.collection);
    return (
      <React.Fragment>
        <div className={styles["audio-magazine-main-page"]}>
          <div className={`js-story-main-container ${`${styles["base"]}`}`}>
            <div className={`${styles["audio-magazine-page-container"]} container`}>
              <div ref={this.StoryCollectionWidgetsRef} className={styles["page-space"]}>
                <AudioCategoriesCollectionCard />
                <h1 className={styles["page-title"]}>Tamil Audio Books</h1>
                <LazyCollection
                  collection={this.props.data.collection}
                  config={this.props.config}
                  collectionTemplates={getAdsWrappedCollectionTemplate}
                  lazyAfter={3}
                  accentColor={"#2f81cd"}
                  pageType={"Audio Magazine"}
                />
                <AudioMagazineListCard config={this.props.config} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazinePage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  }),
  collection: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string
      })
    ).isRequired
  }).isRequired
};
