import React from "react";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import { Icon } from "../../atoms/icon";
import VideoStoryCard from "../../atoms/video-story-card";
import styles from "./styles.m.css";

const SliderVideoCard = ({ collection, title = true }) => {
  if (!collection || !collection.items) {
    return null;
  }
  const stories = collection.items.map(item => item.story);
  if (stories.length === 0) {
    return null;
  }
  let slides = stories.map(story => (
    <VideoStoryCard className={styles["story-card"]} key={story.id} story={story} page="grid-page" />
  ));

  return (
    <div className={`${styles["numbered-items-wrap"]}`}>
      {title &&
        collection.slug &&
        collection.name && (
          <div className={styles["numbered-header"]}>
            <h2 className={styles["title"]}>
              <Icon type={"videographer"} className={styles["video-icon"]} />
              <Link aria-label={`"Read more "${collection.name}`} href={`${collection.pagePath || collection.slug}`}>
                {collection.name}
              </Link>
            </h2>
          </div>
        )}
      <div className={styles["slider-video-grid"]}>{slides}</div>
    </div>
  );
};

SliderVideoCard.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  }),
  title: PropTypes.bool
};

export default SliderVideoCard;
