import React from "react";
import PropTypes from "prop-types";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./audio-magazine.m.css";
import { get } from "lodash";
import ResponsiveImageWithFallback from "../atoms/responsive-image-with-fallback";
import { generateImageSources, checkAppSlug } from "../utils/utils";
import Icon from "../atoms/icon";
import { Link, WithHostUrl } from "@quintype/components";

export class AudioMagazineCollectionListPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  audiomagazineCollectionPath = slug => {
    return `/vikatan-play/${slug}`;
  };

  render() {
    const { data } = this.props;
    const collections = get(data, ["collection", "items"], []);
    const collectionsName = get(data, ["collection", "name"], []);
    const headline = get(data, ["collection", "name"], []);
    const slug = get(data, ["collection", "items", "slug"], []);

    if (!(headline && slug)) {
      return null;
    }

    const checkSlug = checkAppSlug();
    let SourceLink = "";
    if (checkSlug) {
      SourceLink = this.audiomagazineCollectionPath(slug);
    }
    return (
      <React.Fragment>
        <div className={`js-story-main-container ${`${styles["base"]}`}`}>
          <div className={`${styles["collection-of-collection"]} container`}>
            <div className={styles["collection-breadcrums"]}>
              <WithHostUrl>
                {({ primaryHostUrl }) => (
                  <Link
                    className={styles["home-link"]}
                    aria-label="Vikatan"
                    href={primaryHostUrl ? primaryHostUrl + "/vikatan-play" + "/" : "/"}
                  >
                    <span>Home</span>
                  </Link>
                )}
              </WithHostUrl>
              <span>
                <Icon type="slider-next" />
              </span>
              <span className={styles["collection-name"]}>{collectionsName}</span>
            </div>
            <div className={`${styles["collection-of-collection-grid"]}`}>
              {collections.map((collection, index) => {
                const collectionName = collection.name;
                const collectionImage = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
                const collectionImageMetaData = get(
                  collection,
                  ["metadata", "cover-image", "cover-image-metadata"],
                  {}
                );

                const collectionSlug = collection.slug;

                return (
                  <div key={index} className={`${styles["collection-container"]}`}>
                    <Link
                      className={`${styles["read-more-link"]}`}
                      href={this.audiomagazineCollectionPath(collectionSlug)}
                      externalLink={SourceLink}
                    >
                      {collectionImage && (
                        <div className={styles["cover-image"]}>
                          <ResponsiveImageWithFallback
                            className={styles["image-wrapper"]}
                            slug={collectionImage}
                            metadata={collectionImageMetaData}
                            alt={collectionName}
                            imgParams={{ auto: ["format", "compress"] }}
                            sources={generateImageSources(
                              { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                              { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                            )}
                          />
                        </div>
                      )}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazineCollectionListPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
