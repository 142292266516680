import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
// import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { checkAppSlug } from "../../utils/utils";

const AudioCollectionMenuSlider = ({
  collection,
  cardWithImageZoom = true,
  hasTruncatedHeadline = true,
  className = "",
  numberOfCardsInViewDesktop,
  hideTitle = false,
  categoriescard = false
}) => {
  const headline = get(collection, ["title"], "");
  const ImgSrc = get(collection, ["data"]);
  const ImgUrl = get(ImgSrc, ["icon-code"]);
  // const imageCaption = get(collection, ["metadata", "cover-image", "caption"]) || headline;
  const slug = get(collection, ["url"], "");
  // const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  // const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  if (!(headline && slug)) {
    return null;
  }

  const path = slug.replace("https://audiobooks.vikatan.com/", "");
  const audiomagazinePagePath = slug => {
    return `/vikatan-play/${path}`;
  };

  const checkSlug = checkAppSlug();
  let SourceLink = "";
  if (checkSlug) {
    SourceLink = audiomagazinePagePath(slug);
  }
  return (
    <div className={`${categoriescard ? styles["circlecard"] : styles[""]}`}>
      <Link
        className={`${styles["read-more-link"]} ${hideTitle ? styles["maincollection"] : styles[""]} `}
        href={audiomagazinePagePath(slug)}
        externalLink={SourceLink}
      >
        <div
          className={`${styles["base"]} ${numberOfCardsInViewDesktop > 2 ? styles["vertical"] : styles["horizantal"]} `}
        >
          <div className={styles["image-section"]}>
            <img src={ImgUrl} alt="" />
            {/* <ResponsiveImageWithFallback
              className={styles["image-wrapper"]}
              slug={coverImageS3Key}
              metadata={coverImageMetaData}
              alt={imageCaption}
              imgParams={{ auto: ["format", "compress"] }}
              sources={generateImageSources(
                { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
              )}
            /> */}
          </div>
          {!hideTitle && (
            <div className={styles["text-wrapper"]}>
              <div className={styles["bundle-text"]}>
                <h3 className={`${styles["headline"]}`}>
                  <span className={styles.highlight}>{headline}</span>
                </h3>
              </div>
            </div>
          )}
        </div>
      </Link>{" "}
    </div>
  );
};

AudioCollectionMenuSlider.propTypes = {
  cardWithImageZoom: PropTypes.bool,
  hasTruncatedHeadline: PropTypes.bool,
  className: PropTypes.string,
  numberOfCardsInViewDesktop: PropTypes.number,
  hideTitle: PropTypes.bool,
  categoriescard: PropTypes.bool,
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      "cover-image-metadata": PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number
      }),
      "cover-image-s3-key": PropTypes.string,
      caption: PropTypes.string
    })
  })
};

export default AudioCollectionMenuSlider;
