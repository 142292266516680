import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources, getEntities } from "../../utils/utils";
import styles from "./styles.m.css";
import AuthorDetailCard from "../../pages/story/story-templates/shared/author-detail-series-card";
import { EVOLOK } from "../../../integrations";
import { fetchStoryAttributes } from "../../../api";
const VikatanSeriesCardBase = ({
  collection,

  config
}) => {
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");
  let storyLink = get(collection, ["items", 0, "story", "url"], "");
  const Author = get(collection, ["items", 0, "story", "authors"], "");
  const [evAccess, setEvAccess] = useState("ALLOW_ACCESS");

  useEffect(() => {
    const stories = get(collection, ["items"], []);
    const lastStory = stories[stories.length - 1];
    const story = get(lastStory, ["story"], null);

    fetchStoryAttributes(story)
      .then(result => {
        const collectionReverse = get(result, ["collections"], []).reverse();
        const magazines = get(config, ["magazines"], {});
        const getEntityCollection = getEntities(collectionReverse, magazines);
        EVOLOK.loadScript(
          "story-page",
          {
            story: story,
            collection: getEntityCollection
          },
          handleMeteringSuccess
        );
      })
      .catch(console.error);
  }, []);

  const handleMeteringSuccess = response => {
    setEvAccess(response.result);
  };

  return (
    <div className={styles["base"]}>
      <div className={styles["text-wrapper"]}>
        <div className={styles["text-wrapper-section"]}>
          <a href={storyLink}>
            <h5 className={`${styles["author"]}`}>{collection.name}</h5>
            <p className={`${styles["contributor"]}`}>{collection.summary}</p>
          </a>
          <div className={`${styles["author-section"]}`}>
            <AuthorDetailCard authors={Author} config={config} className={styles["author-detail"]} bio={false} />
          </div>
        </div>
        {evAccess !== "ALLOW_ACCESS" ? (
          <div className={`${styles["buttons"]}`}>
            <a href={storyLink} className={`${styles["preview-btn"]}`}>
              Preview
            </a>
            <a
              href={`https://store.vikatan.com/?utm_source=direct_web&utm_medium=series_page&utm_campaign=quick_buy`}
              className={`${styles["buy-btn"]}`}
            >
              Quick Buy
            </a>
          </div>
        ) : (
          ""
        )}
      </div>

      <ResponsiveImageWithFallback
        className={styles["image-wrapper"]}
        slug={coverImageS3Key}
        metadata={coverImageMetaData}
        alt={imgAltText}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
          { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
        )}
      />
    </div>
  );
};

VikatanSeriesCardBase.propTypes = {
  className: PropTypes.string,
  subscriptionHost: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  collection: PropTypes.shape({
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        }),
        "cover-image-s3-key": PropTypes.string
      }),
      magazine: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  prevIssue: PropTypes.object,
  nextIssue: PropTypes.object
};

export const MagazineIssueBundleCard = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(VikatanSeriesCardBase);

export default MagazineIssueBundleCard;
